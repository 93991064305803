const protocol = 'http';
const host = 'localhost';
const port = '5001';
const trailUrl = 'api/v1/';

const hostUrl = `${host}/`;
const endpoint = `${protocol}://${host}${(port ? ':' + port : '')}/${trailUrl}`;

export default {
    host: host,
    apiUrl: trailUrl,
    endpoint: endpoint,
    hostUrl: hostUrl,
};
