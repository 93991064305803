import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const PartnersOutlet = () => {

    return (
        < Outlet />
    )
}

export default PartnersOutlet