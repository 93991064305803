import { EMPLOYER_PROFILE_IMAGE, GET_ALL_USER, GET_ALL_USERL_LOADING, GET_ALL_USER_ERROR, PARTNER_PROFILE_IMAGE, PROFILE_IMAGE_ERROR, PROFILE_IMAGE_LODING, USER_PROFILE_IMAGE } from "../type";

const initialState = {
    getAllUserLoading: false,
    getAllUser: [],
    getAllUserError: [],

    profileImgLoading: false,
    userProfileImgUrl: null,
    employerProfileImgUrl: null,
    partnerProfileImgUrl: null,
    profileImgError: [],
}
export const getAllUserReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_ALL_USERL_LOADING:
            return {
                ...state,
                getAllUserLoading: action.payload,
            };
        case GET_ALL_USER:
            return {
                ...state,
                getAllUser: action.payload,
            };
        case GET_ALL_USER_ERROR:
            return {
                ...state,
                getAllUserError: action.payload,
            };
        default:
            return state
    }
}
export const profileImgReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PROFILE_IMAGE_LODING:
            return {
                ...state,
                profileImgLoading: action.payload,
            };
        case USER_PROFILE_IMAGE:
            return {
                ...state,
                userProfileImgUrl: action.payload,
            };
        case EMPLOYER_PROFILE_IMAGE:
            return {
                ...state,
                employerProfileImgUrl: action.payload,
            };
        case PARTNER_PROFILE_IMAGE:
            return {
                ...state,
                partnerProfileImgUrl: action.payload,
            };
        case PROFILE_IMAGE_ERROR:
            return {
                ...state,
                profileImgError: action.payload,
            };
        default:
            return state
    }
}