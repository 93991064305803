import React, { useEffect, useState } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import { Container } from 'react-bootstrap';
import { FormControl, InputLabel, MenuItem, Pagination, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCoursesAction } from '../redux/actions/courseAction';
import { FiShare2 } from 'react-icons/fi';
import Share from '../helper/sharer/Share';
import { getPatnerCourses } from '../redux/actions/ActiveCourseAction';

interface Filter {
    search: string,
    location: string,
}

const Dashboard = () => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [showCandidate, setShowCandidate] = useState<boolean>(false)
    const [findCandidate, setFindCandidate] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [perPage, setPerPage] = useState<number>(8)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [status, setStatus] = useState("active")
    const [DashboardCourses, setDashboardCourses] = useState<any[]>([])
    const [showShareTool, setShowShareTool] = useState<boolean>(false)
    const [index, setIndex] = useState<any>()

    const dashboardCoursesData = useSelector((state: any) => state.activeCourse.getActiveCourseData)


    useEffect(() => {
        dispatch(getPatnerCourses(perPage, pageNumber))
    }, [perPage, pageNumber])

    useEffect(() => {
        if (dashboardCoursesData && dashboardCoursesData.data && dashboardCoursesData.data.data && dashboardCoursesData.data.data.length > 0) {
            setDashboardCourses(dashboardCoursesData.data.data)
        }
    }, [dashboardCoursesData])

    const changeCandidate = (event: SelectChangeEvent) => {
        setFindCandidate(event.target.value);
    };

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 5,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    const progressBarData = [
        {
            titel: t("Employee.dashboard.progressTable.generalDutyAssistant"),
            value: 15,
            No_Of_app: `15 ${t("Employee.dashboard.progressTable.progressApp")}`
        },
        {
            titel: t("Employee.dashboard.progressTable.UIDesigner"),
            value: 10,
            No_Of_app: `10 ${t("Employee.dashboard.progressTable.progressApp")}`
        },
        {
            titel: t("Employee.dashboard.progressTable.assistantNurset"),
            value: 35,
            No_Of_app: `35 ${t("Employee.dashboard.progressTable.progressApp")}`
        },
        {
            titel: t("Employee.dashboard.progressTable.labTechnician"),
            value: 5,
            No_Of_app: `5 ${t("Employee.dashboard.progressTable.progressApp")}`
        },
    ]

    const message = `Hey, Are you looking for Job? Swayam will really help you get job, here the link of job please click on this link ${window.location.origin}`

    return (

        <>
            <div className='my_profile_main' >
                <Container fluid>
                    {/* <MyProfile /> */}
                    <div className='deshboard-header d-flex flex-wrap justify-content-between align-items-center mb-5' >
                        <h1 className='heading-txt m-3'>{t("Employee.dashboard.title")}</h1>
                        <div className='d-flex flex-wrap position-relative'>
                            {/* <div className='position-relative find_candidate m-3'>
                                <input type="text" id='find-candidate' placeholder={t("Employee.dashboard.input.findCandi")} value={findCandidate} onClick={() => setShowCandidate(!showCandidate)} />
                            </div> */}
                            {/* {
                                showCandidate &&
                                <div className='candidet-item position-absolute'>
                                    <div className='d-flex'>
                                        <h1>General Duty Assistant</h1>
                                        <input type="radio" name='candidate' className='form-check-input' onClick={() => { setFindCandidate('General Duty Assistant'); setShowCandidate(false) }} />
                                    </div>
                                    <div className='d-flex'>
                                        <h1>UI Designer</h1>
                                        <input type="radio" name='candidate' className='form-check-input' onClick={() => { setFindCandidate('UI Designer'); setShowCandidate(false) }} />
                                    </div>
                                    <div className='d-flex'>
                                        <h1>Assistant Nurse</h1>
                                        <input type="radio" name='candidate' className='form-check-input' onClick={() => { setFindCandidate('Assistant Nurse'); setShowCandidate(false) }} />
                                    </div>
                                    <div className='d-flex'>
                                        <h1>Lab Technician</h1>
                                        <input type="radio" name='candidate' className='form-check-input' onClick={() => { setFindCandidate('Lab Technician'); setShowCandidate(false) }} />
                                    </div>
                                </div>
                            } */}


                            <button className='Post_Job m-3' onClick={() => navigate('/partner/postcourses')}>{t("Post a Course")}</button>
                        </div>
                    </div>
                    <div>
                        <Row>
                            <Col lg={2}>
                                <div className='courses-count-box'>
                                    <h4>{dashboardCoursesData && dashboardCoursesData.data && dashboardCoursesData.data.allCoursesCount}</h4>
                                    <h6>{t("Employee.dashboard.progressTable.allCoursesCount")}</h6>
                                </div>
                            </Col>
                            <Col lg={2}>
                                <div className='courses-count-box'>
                                    <h4>{dashboardCoursesData && dashboardCoursesData.data && dashboardCoursesData.data.activeCoursesCount}</h4>
                                    <h6>{t("Employee.dashboard.progressTable.activeCoursesCount")}</h6>
                                </div>
                            </Col>
                            <Col lg={2}>
                                <div className='courses-count-box'>
                                    <h4>{dashboardCoursesData && dashboardCoursesData.data && dashboardCoursesData.data.inactiveCoursesCount}</h4>
                                    <h6>{t("Employee.dashboard.progressTable.inactiveCoursesCount")}</h6>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='deshboard-main mt-3' style={{ overflow: "auto" }}>
                        <div>
                            <h1 className='heading-txt m-0 ps-3 mb-3' style={{ padding: '15px' }}>{t("partner.table.title")}</h1>
                            <div className='top_job_table'>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t("partner.table.label.coursesTitle")}</TableCell>
                                            {/* <TableCell align="right">{t("Active App")}</TableCell>
                                            <TableCell align="right">{t("Shortlisted")}</TableCell>
                                            <TableCell align="right">{t("HiredCand")}</TableCell> */}
                                            <TableCell align="right">{t("partner.table.label.jobStatus")}</TableCell>
                                            <TableCell align="right">{t("partner.table.label.action")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='text-center'>
                                        {DashboardCourses.length > 0 && DashboardCourses.map((row, i) => (
                                            <TableRow
                                                key={i}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align='left' scope="row" onClick={() => navigate(`/partner/viewpartnercourse?id=${row.id}`)}>{row.name}</TableCell>
                                                {/* <TableCell>{row.all_applicants}</TableCell>
                                                <TableCell>{row.selected_applicants}</TableCell>
                                                <TableCell>{row.hired_applicants}</TableCell> */}
                                                <TableCell className='text-center'>{'Open'}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div className="tab-main dashboard">
                                                        <button className="icon show-share-icon cursor-pointer position-relative" style={{ color: "#C90F22", fontSize: "22px", borderRadius: "0px", backgroundColor: "transparent", boxShadow: "none", width: "fit-content" }} onClick={() => { setShowShareTool(!showShareTool), setIndex(i) }} onBlur={() => setShowShareTool(false)}>
                                                            <FiShare2 />
                                                            {showShareTool && i === index && (
                                                                <div className="share">
                                                                    <div className="social-share">
                                                                        <ul>
                                                                            <li onClick={() => Share('facebook', `${message}/employer/dashboard?jobId${row.id}`)}>
                                                                                <span >{t("jobs.share.facebook")}</span>
                                                                                <figure>
                                                                                    {" "}
                                                                                    <img src="../../assets/img/facebook.png" alt="facebook" />{" "}
                                                                                </figure>
                                                                            </li>
                                                                            <li onClick={() => Share('linkedin', `${message}/employer/dashboard?jobId${row.id}`)}>
                                                                                <span>{t("jobs.share.linkedin")}</span>
                                                                                <figure>
                                                                                    {" "}
                                                                                    <img src="../../assets/img/linkedin.png" alt="Linkedin" />{" "}
                                                                                </figure>
                                                                            </li>
                                                                            <li onClick={() => Share('twitter', `${message}/employer/dashboard?jobId${row.id}`)}>
                                                                                <span >{t("jobs.share.twitter")}</span>
                                                                                <figure>
                                                                                    {" "}
                                                                                    <img src="../../assets/img/twitter.png" alt="Twitter" />{" "}
                                                                                </figure>
                                                                            </li>
                                                                            <li onClick={() => Share('whatsapp', `${message}/employer/dashboard?jobId${row.id}`)}>
                                                                                <span >{t("jobs.share.whatsApp")}</span>
                                                                                <figure>
                                                                                    {" "}
                                                                                    <img src="../../assets/img/whatsapp.png" alt="WhatsApp" />{""}
                                                                                </figure>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex mt-3 justify-content-center table_pagination align-items-center mb-3'>
                        <button className='Post_Job ms-3' onClick={() => navigate('/partner/activecourses')}>{t("Employee.dashboard.btn.loadMore")}</button>
                        {/* <button className='btn'>First</button>
            <Pagination count={10} variant="outlined" shape="rounded" />
            <button className='btn'>Last</button> */}
                    </div>

                    <div className='deshboard-main p-4 progress_bar_main'>
                        <div className='d-flex progress_bar flex-wrap justify-content-between mb-4'>
                            <div className='progress_bar_title'>
                                <h1>{t("Employee.dashboard.progressTable.title")}</h1>
                                <p>{'65'} {t("Employee.dashboard.progressTable.subTitle")}</p>
                            </div>
                        </div>
                        {
                            progressBarData.map((item) => (
                                <Row className='align-items-center'>
                                    <Col lg={2}>
                                        <div className='jop_type_name'>
                                            <h1>{item.titel}</h1>
                                        </div>
                                    </Col>
                                    <Col lg={8}>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <BorderLinearProgress variant="determinate" value={item.value} className="progress-bar-content" />
                                        </Box>
                                    </Col>
                                    <Col lg={2}>
                                        <h2>{item.No_Of_app}</h2>
                                    </Col>
                                </Row>
                            ))
                        }
                    </div>
                </Container>
            </div >
        </>
    )
}

export default Dashboard