import React, { useEffect, useState } from 'react'
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import ChooseLanguage from '../../components/modals/ChooseLanguage'
import NotificationsModel from '../../components/modals/NotificationsModel'
import deshbord_active from '../../assets/img/deshbord.png'
import deshbord from '../../assets/img/disable_deshboard.png'
import job from '../../assets/img/noun-search-job-4641981.png'
import active_job from '../../assets/img/active_job.png'
import inactive_job from '../../assets/img/active-job.png'
import invitations from '../../assets/img/invitations.png'
import invitations_Active from '../../assets/img/invitations-active.png'
import active_Inactive_Jobs from '../../assets/img/active_Inactive_Jobs.png'
import profile_pic from '../../assets/img/icon.png'
import Logo_white from "../../assets/img/Logo_white.png"
import AuthStorage from '../../helper/AuthStorage'
import STORAGEKEY from '../../config/APP/app.config'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Fade, Menu, MenuItem } from '@mui/material'
import { toast } from 'react-toastify'
import { IsProfileImage } from '../../redux/actions/isLoginAction'
import ChangeUserPasswordModal from '../../components/modals/ChangeUserPasswordModal'
const Header = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const [showNotifications, setShowNotifications] = useState(false)
    const [chooseLanguage, setChooseLanguage] = useState(false)
    const [changePassword, setChangePassword] = useState(false)
    const [profileImg, setProfileImg] = useState<string | null>()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const getUserData = useSelector((state: any) => state.login.getUserData)
    const profileImageURL = useSelector((state: any) => state.isProfile.isProfileImage)
    const profileImage = useSelector((state: any) => state.profileImg.partnerProfileImgUrl)

    useEffect(() => {
        if (profileImage) {
            setProfileImg(URL.createObjectURL(profileImage))
        }
    }, [profileImage])

    useEffect(() => {
        if (getUserData && getUserData.data && getUserData.data.avatar) {
            setProfileImg(getUserData.data.avatar)
        } else if (profileImageURL) {
            setProfileImg(profileImageURL)
        } else {
            setProfileImg(AuthStorage.getStorageData(STORAGEKEY.profileImg))
        }
    }, [profileImageURL, getUserData])

    const navbardata = [
        {
            img: deshbord,
            img_active: deshbord_active,
            text: "Dashboard",
            path: `/dashboard`
        },
        {
            img: job,
            img_active: active_job,
            text: "Active course",
            path: `/activecourses`
        },
        {
            img: inactive_job,
            img_active: active_Inactive_Jobs,
            text: "Inactive course",
            path: `/inactivecourses`
        },
        {
            img: invitations,
            img_active: invitations_Active,
            text: "Post course",
            path: `/postcourses`
        },
    ]

    const IsActive = (path: string) => {
        let currentLocation = location.pathname.split('/')[2]
        return currentLocation === path.split('/')[1]
    }

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/');
        toast.success("Logout successfully");
        handleClose()
        dispatch(IsProfileImage(''))
    }

    const gotoProfile = () => {
        navigate('/partner/myprofile')
        handleClose()
    }

    const gotoDashboard = () => {
        if (getUserData.data.isActive) {
            navigate('/partner/dashboard')
            handleClose()
        }
    }


    return (
        <>
            <div className="white-navbar">
                <Navbar bg="" expand={false} fixed="top">
                    <Container fluid className='px-5'>
                        <div>
                            <img src={Logo_white} alt="" width="64px" className="ms-3" onClick={() => navigate(getUserData.data.isActive ? '/partner/dashboard' : '/partner/inactive_partner')} />
                        </div>
                        <div className='d-flex gap-5'>
                            <>
                                <Button
                                    className="header-profile position-relative p-0"
                                    style={{ position: "relative", background: "transparent", width: "36px", height: "36px", padding: "0px" }}
                                    id="fade-button"
                                    aria-controls={open ? 'fade-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <div className="header-profile position-relative p-0">
                                        <img src={profileImg && profileImg !== 'null' ? profileImg : profile_pic} alt="" width="100%" height="100%" className="" style={{ cursor: "pointer", objectFit: 'cover' }} />
                                    </div>
                                </Button>

                                <Menu
                                    className='navBar-model'
                                    id="fade-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'fade-button',
                                    }}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    TransitionComponent={Fade}
                                >
                                    <MenuItem onClick={gotoDashboard}>
                                        <div className={'navbar-body'} >
                                            <img src={deshbord} className={"d-block"} alt="" />
                                            <Nav.Link onClick={() => { }}>Dashboard</Nav.Link>
                                        </div>
                                    </MenuItem>
                                    <MenuItem onClick={gotoProfile}>Profile</MenuItem>
                                    <MenuItem onClick={() => {
                                        setChangePassword(true)
                                        handleClose()
                                    }}>Change password</MenuItem>
                                    <MenuItem onClick={logout}>Logout</MenuItem>
                                </Menu>
                            </>
                        </div>
                    </Container>
                </Navbar>
            </div>

            {
                showNotifications && <NotificationsModel show={showNotifications} onHide={() => setShowNotifications(false)} />
            }

            {
                chooseLanguage && <ChooseLanguage show={chooseLanguage} onHide={() => setChooseLanguage(false)} />
            }
            {
                changePassword && <ChangeUserPasswordModal show={changePassword} onHide={() => setChangePassword(false)} />
            }
            <div className="blue-navbar">
                <Navbar bg="" expand={false} fixed="top" style={{ height: "90px" }}>
                    <Container fluid>
                        <div>
                            <Navbar.Toggle aria-controls="offcanvasNavbar" />
                            <img src="../../assets/img/Logo_white.png" onClick={() => navigate(getUserData.data.isActive ? '/partner/dashboard' : '/partner/inactive_partner')} alt="" width="40px" className="ms-3 cursor-pointer" />
                        </div>
                        <>
                            <Button
                                className="header-profile position-relative p-0"
                                style={{ position: "relative", background: "transparent", width: "36px", height: "36px", padding: "0px" }}
                                id="fade-button"
                                aria-controls={open ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <div className="header-profile position-relative p-0">
                                    <img src={profileImg && profileImg !== null ? profileImg : profile_pic} alt="" width="100%" height="100%" className="" style={{ cursor: "pointer", objectFit: 'cover' }} />
                                </div>
                            </Button>

                            <Menu
                                className='navBar-model'
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                            >
                                <MenuItem onClick={gotoDashboard}>
                                    <div className={'navbar-body'} >
                                        <img src={deshbord} className={"d-block"} alt="" />
                                        <Nav.Link onClick={() => { }}>Dashboard</Nav.Link>
                                    </div>
                                </MenuItem>
                                <MenuItem onClick={gotoProfile}>Profile</MenuItem>
                                <MenuItem onClick={() => {
                                    setChangePassword(true)
                                    handleClose()
                                }}>Change password</MenuItem>
                                <MenuItem onClick={logout}>Logout</MenuItem>
                            </Menu>
                        </>
                        <Navbar.Offcanvas
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel"
                            placement="start"
                        >
                            <Offcanvas.Header className="px-0">
                                <Offcanvas.Title id="offcanvasNavbarLabel">
                                    <Offcanvas.Title id="offcanvasNavbarLabel">
                                        <img src="../../assets/img/Logo_white.png" onClick={() => navigate(getUserData.data.isActive ? '/partner/dashboard' : '/partner/inactive_partner')} className="cursor-pointer" alt="" width="100px" />
                                    </Offcanvas.Title>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className="px-0">
                                <Nav>
                                    <div style={{ width: "100%" }}>
                                        {
                                            navbardata.map((item) => (
                                                <div className='sidebar-nav-body'>
                                                    <img src={`${IsActive(item.path) ? item.img_active : item.img}`} className={item.img ? "d-block" : "d-none"} alt="" />
                                                    <NavLink to={`/partner${item.path}`} className={`${!getUserData?.data.isActive && "disabled-link"} ${IsActive(item.path) ? 'active' : 'disable'}`}>{item.text}</NavLink>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            </div>
        </>
    )
}

export default Header