import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ChangePasswordAction, ChangeUserPasswordAction, forgotAction } from '../../redux/actions/loginAction';
import { RootState } from '../../redux/store';
import { CHANGE_PASSWORD, CHANGE_PASSWORD_ERROR, CHANGE_USER_PASSWORD, FORGOT_PASSWORD } from '../../redux/type';
import { useTranslation } from "react-i18next";

interface Props {
    onHide: () => void;
    show?: boolean;
    token?: any;
    forgotEmail?: any;
}
interface ChangeData {
    curPass: string,
    pass: string,
    conPass: string,
}

interface Password {
    curPass: string
    pass: string,
    conPass: string
}

const ChangeUserPasswordModal: React.FC<Props> = ({ show, onHide, forgotEmail }) => {


    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [password, setPassword] = useState<Password>({
        curPass: '',
        pass: '',
        conPass: ''
    })

    const [passType, setpassType] = useState<any>({
        curPass: 'password',
        pass: 'password',
        conPass: 'password'
    })

    const [error, setError] = useState<any>()

    const changePasswordData = useSelector((state: RootState) => state.login.changeUser)
    const handleChange = (e: any, name: any) => {
        setError({ ...error, [name]: '' })
        if (name === "pass" || name === 'conPass' || name === "curPass") {
            setPassword({ ...password, [name]: e.target.value })
        }
    }

    const validation = () => {
        let passregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        let flag = false;
        let error: ChangeData = {
            curPass: "",
            pass: "",
            conPass: "",
        }
        if (!password.curPass) {
            error.curPass = "Please enter current password";
            flag = true
        }
        if (!password.pass) {
            error.pass = "Please enter new password";
            flag = true
        }
        else if (password.curPass === password.pass) {
            error.pass = "New Password can not be same as Current password";
            flag = true
        }
        else if (!passregex.test(password.pass)) {
            error.pass = "Please enter valid password";
            flag = true
        }
        if (!password.conPass) {
            error.conPass = "Please confirm password";
            flag = true
        }
        else if (password.pass !== password.conPass) {
            error.conPass = "Password does not match";
            flag = true
        }
        setError(error)
        return flag
    }

    const save = () => {
        if (validation()) {
            return;
        }
        let body = {
            currentPassword: password.curPass,
            newPassword: password.conPass
        }
        dispatch(ChangeUserPasswordAction(body))
    }

    useEffect(() => {
        if (changePasswordData) {
            if (changePasswordData.status === 200) {
                toast.success(changePasswordData.message)
                onHide()
            } else {
                toast.error(changePasswordData.message)
            }
            dispatch({
                type: CHANGE_USER_PASSWORD,
                payload: ''
            })
        }
    }, [changePasswordData])


    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="p-1 p-sm-5 change-pass-model"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className='change-pass-model-header'>
                </Modal.Header>
                <Modal.Body>
                    <div className="forget-pass-model-body">
                        <h1>{t("changepasswordmodal.title")}</h1>
                        <div className='d-flex position-relative email-num-input'>
                            <TextField type={passType.curPass} label={'Enter current password'} value={password.curPass} onChange={(e) => handleChange(e, 'curPass')} />
                            <img src={passType.curPass === 'password' ? "../assets/img/eye.png" : "../assets/img/Eyes.png"} alt="" onClick={() => setpassType({ ...passType, curPass: passType.curPass === "password" ? "text" : "password" })} />
                        </div>
                        {error?.curPass && <span style={{ color: 'red' }}>{error.curPass}</span>}
                        <div className='d-flex position-relative email-num-input'>
                            <TextField type={passType.pass} label={t("changepasswordmodal.pass")} value={password.pass} onChange={(e) => handleChange(e, 'pass')} />
                            <img src={passType.pass === 'password' ? "../assets/img/eye.png" : "../assets/img/Eyes.png"} alt="" onClick={() => setpassType({ ...passType, pass: passType.pass === "password" ? "text" : "password" })} />
                        </div>
                        {error?.pass && <span style={{ color: 'red' }}>{error.pass}</span>}
                        <div className='d-flex varifications'>
                            <img src="../assets/img/greenTick.png" alt="" />
                            <p>{t("changepasswordmodal.cond_1")}</p>
                        </div>
                        <div className='d-flex varifications'>
                            <img src="../assets/img/greenTick.png" alt="" />
                            <p>{t("changepasswordmodal.cond_2")}</p>
                        </div>
                        <div className='d-flex varifications'>
                            <img src="../assets/img/greenTick.png" alt="" />
                            <p>{t("changepasswordmodal.cond_3")}</p>
                        </div>
                        <div className='d-flex varifications'>
                            <img src="../assets/img/greenTick.png" alt="" />
                            <p>{t("changepasswordmodal.cond_4")}</p>
                        </div>
                        <div className='d-flex position-relative email-num-input'>
                            <TextField type={passType.conPass} label={t("changepasswordmodal.conf_pass")} value={password.conPass} onChange={(e) => handleChange(e, 'conPass')} />
                            <img src={passType.conPass === 'password' ? "../assets/img/eye.png" : "../assets/img/Eyes.png"} alt="" onClick={() => setpassType({ ...passType, conPass: passType.conPass === "password" ? "text" : "password" })} />
                        </div>
                        {error?.conPass && <span style={{ color: 'red' }}>{error.conPass}</span>}
                        <div className='d-flex justify-content-around mt-5'>
                            <button className='Continue-bnt' onClick={() => save()}>{t("changepasswordmodal.save_btn")}</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChangeUserPasswordModal