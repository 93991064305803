import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import WriteToUs from '../../components/modals/WriteToUs';
import STORAGEKEY from '../../config/APP/app.config';
import AuthStorage from '../../helper/AuthStorage';
import { getContactUs } from '../../redux/actions/ContactUs';
import { useTranslation } from 'react-i18next';

const Contact = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [writeToUs, setWriteToUs] = useState(false);

    const openWriteToUs = () => {
        setWriteToUs(true)
    }

    const contactUsData = useSelector((state: any) => state.contactUs.contactUsData)


    useEffect(() => {
        if (AuthStorage.getStorageData(STORAGEKEY.language)) {
            dispatch(getContactUs(AuthStorage.getStorageData(STORAGEKEY.language)))
        }
    }, [AuthStorage.getStorageData(STORAGEKEY.language)])

    const redirecthyperLink = (link: string) => {
        let doc = document;
        let a = doc.createElement("a");
        a.href = link;
        a.target = '_blank';
        a.click();
    }


    return (
        <>
            <div className="termsandconditions_bg contact">
                <Container>
                    <div className="breadcrums">
                        <button onClick={() => navigate("/")}>{t("breadcrums.home")}</button> <p>{`>`}</p> <button>{t("breadcrums.contact")}</button>
                    </div>
                    <div className='careers-titel'>
                        <h1>{t("contactpage.title")}</h1>
                    </div>
                    <div className="growtogathercard gy-3">
                        <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                            <div className="m-3 contact-card">
                                <img src="./assets/img/Group 16.png" />
                                <h4 className="title">{t("contactpage.help")}</h4>
                                <p className="text mb-0">{t("contactpage.helptxt")}</p>
                                <button className='red-button mt-3' onClick={() => navigate('/faqs')}>{t("contactpage.faqbtn")}</button>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                            <div className="m-3 contact-card">
                                <img src="./assets/img/Group 4.png" />
                                <h4 className="title">{t("contactpage.collaborate")}</h4>
                                <p className="text mb-0">{contactUsData?.data?.collaborateName}</p>
                                <p style={{ color: '#C90F22', fontWeight: 'bold' }}>{contactUsData?.data?.collaborateEmail}</p>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                            <div className="m-3 contact-card">
                                <img src="./assets/img/Group 19.png" />
                                <h4 className="title">{t("contactpage.writeus")}</h4>
                                <p className="text mb-0">{t("contactpage.writetxt")}</p>
                                <button onClick={() => openWriteToUs()} className='red-button mt-3'>{t("contactpage.writebtn")}</button>
                            </div>
                        </Col>
                        <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                            <div className="m-3 contact-card">
                                <img src="./assets/img/Group 4 (1).png" />
                                <h4 className="title">{t("contactpage.callus")}</h4>
                                <p style={{ color: '#C90F22', fontWeight: 'bold' }}>{contactUsData?.data?.mobile} / {contactUsData?.data?.contactNo}</p>
                            </div>
                        </Col>

                    </div>
                </Container>
            </div>

            <div className="connect-map">
                <iframe src={contactUsData?.data?.mapLink} allowFullScreen></iframe>
            </div>
            <Container>

                <div className="contact-detail">
                    <Row>
                        <Col lg={8}>
                            <h1 className="title">
                                {contactUsData?.data?.location}
                            </h1>
                            <div className="registered-address">
                                <h2>{t("contactpage.address")}</h2>
                                <h2>{contactUsData?.data?.companyName}</h2>
                                <h6>{contactUsData?.data?.companyAddress}</h6>
                            </div>
                            <div className="registered-address mt-3">
                                <h2>{t("contactpage.working_hr")}</h2>
                                {/* <h6>Monday to Friday 9 am to 6pm</h6> */}
                                <h6>{contactUsData?.data?.officeHours}</h6>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <h1 className='link-title'>{t("contactpage.connectwithus")}</h1>
                            <div className="connect-logos">
                                <img src="./assets/img/connect-facebook.png" alt="fb" onClick={() => redirecthyperLink(contactUsData?.data?.facebookLink)} />
                                <img src="./assets/img/connect-twitter.png" alt="twitter" onClick={() => redirecthyperLink(contactUsData?.data?.twitterLink)} />
                                <img src="./assets/img/connect-in.png" alt="in" onClick={() => redirecthyperLink(contactUsData?.data?.linkedinLink)} />
                                <img src="./assets/img/connect-insta.png" alt="insta" onClick={() => redirecthyperLink(contactUsData?.data?.instagramLink)} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            {
                writeToUs && <WriteToUs show={writeToUs} onHide={() => setWriteToUs(false)} />
            }
        </>
    )
}

export default Contact