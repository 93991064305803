import React, { useEffect, useState } from "react";
import { CgSearch } from "react-icons/cg";
import { BiChevronDown } from "react-icons/bi";
import { log } from "console";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SelectSearch = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [selectorVal, setSelectorVal] = useState('');
  const [showOpt, setShowOpt] = useState(false);
  const [search, setSearch] = useState("");

  const selectorList = [
    {
      list: t("Home.selectedsearch.learn"),
    },
    {
      list: t("Home.selectedsearch.job"),
    },
    {
      list: t("Home.selectedsearch.schemes"),
    },
  ];

  const redirect = () => {
    const q = search.split(' ').join('+')
    if (!selectorVal || selectorVal === t("Home.selectedsearch.learn")) {
      if (search) {
        navigate(`/view_all_course?q=${q}`)
      } else {
        navigate(`/view_all_course`)
      }
    } else if (selectorVal === t("Home.selectedsearch.job")) {
      if (search) {
        navigate(`/view_all_jobs?q=${q}`)
      } else {
        navigate(`/view_all_jobs`)
      }
    } else if (selectorVal === t("Home.selectedsearch.schemes")) {
      if (search) {
        navigate(`/view_all_schemes?q=${q}`)
      } else {
        navigate(`/view_all_schemes`)
      }
    }
  }
  const handalChange = (event: any) => {
    setSearch(event)
  }

  return (
    <>
      <div className="slector-search">
        <div className="slector-search-inner">
          <div className="selector" onClick={() => setShowOpt(!showOpt)}>
            <p>{selectorVal ? selectorVal :  t("Home.selectedsearch.learn")}</p>
            <div className={showOpt ? "uparrow" : "downarrow"}>
              <BiChevronDown />
            </div>
            {showOpt && <div className="selector-option">
              <ul>
                {selectorList.map((item, i) => (
                  <li key={item.list} className={selectorVal === item.list ? "text-bold" : ''} onClick={() => setSelectorVal(item.list)}>
                    {item.list}
                  </li>
                ))}
              </ul>
            </div>}
          </div>
          <div className="search">
            <input type="text" placeholder={t("Home.selectedsearch.lable")} onChange={(event: any) => handalChange(event.target.value)} />
            <div className="search-icon">
              <CgSearch onClick={redirect} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectSearch;
