import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { RootState } from '../../redux/store'
import { FORGOT_PASSWORD } from '../../redux/type'

const ForgotPasswordSuccesModel = ({ show, onHide, forgotValue }: any) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const forgotData = useSelector((state: RootState) => state.login.forgot)

    useEffect(() => {
        if (forgotData && forgotData.status === 200 && forgotData.message === "Link sent successfully.") {
            toast.success(forgotData.message)
            dispatch({
                type: FORGOT_PASSWORD,
                payload: null
            })
        }
    }, [forgotData])

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="p-1 p-sm-5 forget-pass-succes-model"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className='forget-pass-succes-model-header'></Modal.Header>
                <Modal.Body>
                    <div className="mt-3 forget-pass-succes-model-body">
                        <h1>{t("ForgotPasswordSuccessModal.title")}</h1>
                        <img src="./assets/img/check.png" alt="" />
                        <h2 className="">{t("ForgotPasswordSuccessModal.description")}</h2>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ForgotPasswordSuccesModel