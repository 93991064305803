import { useEffect, useState } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import Applied from "../../assets/img/portfolio.png";
import { useNavigate } from 'react-router-dom';
import Drawer from '../../components/drawer/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../redux/actions/loginAction';
import { ApiGet } from '../../helper/API/ApiData';
import AuthStorage from '../../helper/AuthStorage';
import STORAGEKEY from '../../config/APP/app.config';
import { getAllState } from '../../redux/actions/stateAction';
import { useTranslation } from "react-i18next";

const MyProfile = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()


    const getUserData = useSelector((state: any) => state.login.getUserData)
    const allStateData = useSelector((state: any) => state.stateData.stateData)

    const [drawer, setDrawer] = useState(false)
    const [userData, setUserData] = useState<any>([])
    const [cardData, setCardData] = useState<any>([])
    const [title, setTitle] = useState<any>()

    useEffect(() => {
        dispatch(getUser())
        dispatch(getAllState(undefined))
    }, [])

    useEffect(() => {
        if (getUserData && getUserData.data) {
            setUserData(getUserData.data)
        }
        AuthStorage.setStorageData(STORAGEKEY.profileImg, getUserData?.data?.avatar, true);
    }, [getUserData])


    const editprofile = () => {
        navigate('/edit-profile')
    }
    const handalClick = (text: any, txt: any) => {
        let url = ''
        if (text === "Applied_jobs") {
            url = `user/auth/getAppliedJobsByUser`
        } else if (text === "Saved_jobs") {
            url = `user/auth/getSavedJobsByUser`
        } else if (text === "Ongoing_courses") {
            url = `user/auth/getOngoingCourseByUser`
        } else if (text === "Saved_courses") {
            url = `user/auth/getSavedCoursesByUser`
        } else if (text === "Saved_schemes") {
            url = `user/auth/getSavedSchemesByUser`
        } else if (text === "Saved_news") {
            url = `user/auth/getSavedNewsByUser`
        } else if (text === "Enrolled_schemes") {
            url = `user/auth/getEnrolledSchemesByUser`
        }
        ApiGet(`${url}?userId=${userData.id}`)
            .then((res: any) => {
                if (res && res.data && text === "Applied_jobs") {
                    setCardData(res && res.data && res.data.length && res.data.map((item: any) => {
                        return {
                            name: item.job_details?.jobType?.name,
                            thumbnail: item.job_details.thumbnail,
                            detail: item.job_details.description,
                            type: "JOB",
                            navigateTo: "view_all_jobs",
                        }
                    }))
                }
                else if (res && res.data && text === "Saved_jobs") {
                    setCardData(res && res.data && res.data.length && res.data.map((item: any) => {
                        return {
                            name: item.SelectedJob?.jobType?.name,
                            thumbnail: item.SelectedJob.thumbnail,
                            detail: item.SelectedJob.description,
                            type: "JOB",
                            navigateTo: "view_all_jobs",
                        }
                    }))
                } else if (res && res.data && text === "Ongoing_courses") {
                    setCardData(res && res.data && res.data.length && res.data.map((item: any) => {
                        return {
                            name: item.SelectedCourse?.name,
                            thumbnail: item.SelectedCourse.thumbnail,
                            detail: item.SelectedCourse.detail,
                            type: "COURSE",
                            navigateTo: "view_all_course",
                        }
                    }))
                } else if (res && res.data && text === "Saved_courses") {
                    setCardData(res && res.data && res.data.length && res.data.map((item: any) => {
                        return {
                            name: item.SelectedCourse?.name,
                            thumbnail: item.SelectedCourse.thumbnail,
                            detail: item.SelectedCourse.detail,
                            type: "COURSE",
                            navigateTo: "view_all_course",
                        }
                    }))
                } else if (res && res.data && text === "Saved_schemes") {
                    setCardData(res && res.data && res.data.length && res.data.map((item: any) => {
                        return {
                            name: item.SelectedScheme?.name,
                            thumbnail: item.SelectedScheme.thumbnail,
                            detail: item.SelectedScheme.detail,
                            type: "SCHEME",
                            navigateTo: "view_all_schemes",
                        }
                    }))
                } else if (res && res.data && text === "Saved_news") {
                    setCardData(res && res.data && res.data.length && res.data.map((item: any) => {
                        return {
                            name: item.SelectedNews?.title,
                            thumbnail: item.SelectedNews.imageUrl,
                            detail: item.SelectedNews.body,
                            type: "NEWS",
                            navigateTo: "viewAllNews",
                        }
                    }))
                } else if (res && res.data && text === "Enrolled_schemes") {
                    setCardData(res && res.data && res.data.length && res.data.map((item: any) => {
                        return {
                            name: item.SelectedScheme?.name,
                            thumbnail: item.SelectedScheme.thumbnail,
                            detail: item.SelectedScheme.detail,
                            type: "SCHEME",
                            navigateTo: "view_all_schemes",
                        }
                    }))
                }
            })
        setDrawer(true)
        setTitle(txt)
    }
    const otherInfo = [
        {
            img: Applied,
            txt: t("myprofile.applied_jobs"),
            total: userData?.jobApplication,
            text: "Applied_jobs"
        },
        {
            img: Applied,
            txt: t("myprofile.saved_jobs"),
            total: userData?.savedJobs,
            text: "Saved_jobs"
        },
        {
            img: Applied,
            txt: t("myprofile.ongoing_course"),
            total: userData?.enrolledCourses,
            text: "Ongoing_courses"

        },
        {
            img: Applied,
            txt: t("myprofile.saved_course"),
            total: userData?.savedCourses,
            text: "Saved_courses"

        },
        {
            img: Applied,
            txt: t("myprofile.saved_scheme"),
            total: userData?.savedSchemes,
            text: "Saved_schemes"
        },
        {
            img: Applied,
            txt: t("myprofile.enrolled_schemes"),
            total: userData?.enrolledSchemes,
            text: "Enrolled_schemes"
        },
        {
            img: Applied,
            txt: t("myprofile.saved_news"),
            total: userData?.savedNews,
            text: "Saved_news"
        }
    ]

    return (
        <>
            <div className='my_profile_main'>
                <Container>
                    <h1 className='heading-txt'>{t("myprofile.title")}</h1>
                    <div className='profile-view'>
                        <Row className='gap-4 gap-xl-0 gap-xxl-0'>
                            <Col xxl={5} xl={6} lg={12}>
                                <Row className='border-right'>
                                    <Col lg={5} md={4} sm={5}>
                                        <div className='profile-detail' style={{ display: 'flex', flexDirection: 'column' }}>
                                            <img className='profile_picture' src={userData?.avatar && userData?.avatar !== "null" ? userData?.avatar : '../../assets/img/icon.png'} alt="profile picture not found" />
                                            <button><img src='./assets/img/Mask (1).png' />
                                                <a href={userData && userData.resumeUrl && userData.resumeUrl !== 'null' && userData?.resumeUrl}
                                                    download
                                                    target={"_blank"} className="download-file">
                                                    {t("myprofile.downld_btn")}
                                                </a></button>
                                        </div>
                                    </Col>
                                    <Col lg={7} md={8} sm={7}>
                                        <div className='employee-detail mt-3 mt-sm-0'>
                                            <h1>{userData?.name}</h1>
                                            {/* <h6>{userData?.selectedJobRole}</h6> */}
                                            <div className='d-flex align-items-center mt-3'>
                                                <img src="./assets/img/Shape_2.png" alt="" />
                                                <h2 className='ms-2'>{userData && userData.state && allStateData?.data?.find((ele: any) => ele.id === userData.state)?.name}</h2>
                                            </div>
                                            <div className='d-flex align-items-center mt-3'>
                                                <img src="./assets/img/telephone.png" alt="" />
                                                <h2 className='ms-2'>+91 {userData?.phone}</h2>
                                            </div>
                                            <div className='profile_social'>
                                                <img src="./assets/img/Path@2x.png" style={{ width: "13px", cursor: "pointer" }} onClick={() => { userData && userData.facebookLink && userData?.facebookLink !== "null" && userData?.facebookLink ? window.open(userData?.facebookLink) : "" }} />
                                                <img src="./assets/img/Path-1@2x.png" style={{ cursor: "pointer" }} onClick={() => { userData && userData.twitterLink && userData?.twitterLink !== "null" && userData.twitterLink ? window.open(userData?.twitterLink) : "" }} />
                                                <img src="./assets/img/Group 14@2x.png" style={{ cursor: "pointer" }} onClick={() => { userData && userData.linkedInLink && userData?.linkedInLink !== "null" && userData?.linkedInLink ? window.open(userData?.linkedInLink) : "" }} />
                                                <img src="./assets/img/noun-insta-3324511.svg" style={{ cursor: "pointer" }} onClick={() => { userData && userData.instagramLink && userData?.instagramLink !== "null" && userData?.instagramLink ? window.open(userData?.instagramLink) : "" }} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xxl={7} xl={6} lg={12}>
                                <div className='social-detail-main'>
                                    <div className='social-detail'>
                                        <button onClick={() => editprofile()}>{t("myprofile.editbtn")}</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='mt-2'>
                        <Row className='' style={{ rowGap: '15px' }}>
                            {otherInfo.map((item, i) => (
                                <Col lg={2}>
                                    <div className='other-infos cursor-pointer' onClick={() => handalClick(item.text, item.txt)}>
                                        <img src={item.img} alt="" />
                                        <p>{item.txt}</p>
                                        <h1>{item.total}</h1>
                                    </div>
                                </Col>
                            ))
                            }

                        </Row>
                    </div>
                </Container>
            </div>
            {
                drawer && <Drawer handleClose={() => setDrawer(false)} show={drawer} data={cardData} heding={title} />
            }

        </>
    )
}

export default MyProfile