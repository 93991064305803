import API_LOCAL from './api-local';
import API_PROD from './api-prod';

const hostname = window.location.hostname;
const port = window.location.port;
let isLocalApi = +port >= 3000;

export const HINDI_ID = 'd42efdb0-4739-4f88-980f-4b96b902e453'
export const ENGLISH_ID = '265af1c8-af64-4861-80eb-2d8c0f4ff4d1'
export const MARATHI_ID = 'c414e84f-3571-4d11-8ff0-7532c45568fd'

export const API = (hostname === 'localhost' && isLocalApi) ? API_LOCAL : API_PROD
