import { FC, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import $ from "jquery";
import Profiling_Two_Color from '../assets/img/Profiling_Two Color.png'
interface Props {
  // any props that come into the component
}
const Layout: FC<Props> = ({ children, ...props }) => {

  const [top, setTop] = useState(false);
  useEffect(() => {
    $(document).ready(function () {
      $(window).scrollTop(0);
    });
  }, [children, top]);


  return (
    <>

      <div className="main-page">
        <div className="px-0">
          <Header />
          <div className="" style={{minHeight:'616px'}} {...props}>{children}</div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;
