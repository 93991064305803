import React, { useEffect, useState } from 'react'
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Button, Col, Container, Row } from 'react-bootstrap'
import UploadImageResume from '../../components/modals/UploadImageResume'
import { useDispatch, useSelector } from 'react-redux'
import { getAllState } from '../../redux/actions/stateAction'
import { ApiGet, ApiPost } from '../../helper/API/ApiData'
import AuthStorage from '../../helper/AuthStorage'
import STORAGEKEY from '../../config/APP/app.config'
import { Body } from 'react-bootstrap/lib/Media'
import { getUser } from '../../redux/actions/loginAction'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import DeleteProfile from '../../components/modals/DeleteProfile'
import { IsProfileImage } from '../../redux/actions/isLoginAction'
import Tick from '../../assets/img/tick.png'
import { useTranslation } from "react-i18next";

const EditProfile = () => {
    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} checkedIcon={<img src={Tick} />} defaultChecked={input.availableForHire} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#C90F22',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#fff',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const userType: any = AuthStorage.getStorageData(STORAGEKEY.userType)

    useEffect(() => {
        dispatch(getAllState(undefined))
    }, [])

    const allStateData = useSelector((state: any) => state.stateData.stateData)
    const getUserData = useSelector((state: any) => state.login.getUserData)

    const [uploadImageResume, setUploadImageResume] = useState(false);
    const [uploadResume, setUploadResume] = useState(false);
    const [deleteProfile, setDeleteProfile] = useState(false);
    const [uploadPhotoResume, setUploadPhotoResume] = useState("");
    const [uploadHeader, setUploadHeader] = useState("");
    const [uploadtext, setUploadtext] = useState("");
    const [qualification, setQualification] = useState<any>([]);
    const [img, setImg] = useState<any>();
    const [fileError, setFileError] = useState<any>();
    const [error, setError] = useState<any>({});

    const [input, setInput] = useState<any>({
        availableForHire: true,
        name: '',
        phone: '',
        email: '',
        location: '',
        facebook: '',
        twitter: '',
        linkedIn: '',
        instagram: '',
        intrested: '',
        qualification: '',
        aboutYou: '',
        experience: '',
        avatar: '',
        certification: '',
        completeCourse: '',
        enrolledCourses: '',
        jobApplication: '',
        priority: '',
        resumeUrl: '',
        savedCourses: '',
        savedJobs: '',
        savedSchemes: '',
        selectedJobRole: '',
        userType: '',
        workExperience: '',
        companyName: '',
        companyLogo: '',
        isActive: true,
        isDeleted: false,
        sendEmail: true,
    });

    useEffect(() => {
        setInput({
            availableForHire: getUserData?.data?.availableForHire ?? '',
            name: getUserData?.data?.name ?? '',
            phone: getUserData?.data?.phone ?? '',
            email: getUserData?.data?.email ?? '',
            location: getUserData?.data?.state ?? '',
            facebook: getUserData?.data?.facebookLink ?? '',
            twitter: getUserData?.data?.twitterLink ?? '',
            linkedIn: getUserData?.data?.linkedInLink ?? '',
            instagram: getUserData?.data?.instagramLink ?? '',
            qualification: getUserData?.data?.qualification ?? '',
            intrested: getUserData?.data?.intrested ?? '',
            aboutYou: getUserData?.data?.aboutMe ?? '',
            experience: getUserData?.data?.experience ?? '',
            avatar: getUserData?.data?.avatar ?? '',
            certification: getUserData?.data?.certification ?? '',
            completeCourse: getUserData?.data?.completeCourse ?? '',
            enrolledCourses: getUserData?.data?.enrolledCourses ?? '',
            id: getUserData?.data?.id ?? '',
            jobApplication: getUserData?.data?.jobApplication ?? '',
            priority: getUserData?.data?.priority ?? '',
            resumeUrl: getUserData?.data?.resumeUrl ?? '',
            savedCourses: getUserData?.data?.savedCourses ?? '',
            savedJobs: getUserData?.data?.savedJobs ?? '',
            savedSchemes: getUserData?.data?.savedSchemes ?? '',
            selectedJobRole: getUserData?.data?.selectedJobRole ?? '',
            userType: getUserData?.data?.userType ?? '',
            workExperience: getUserData?.data?.workExperience ?? '',
            companyName: getUserData?.data?.companyName ?? '',
            companyLogo: getUserData?.data?.companyLogo ?? '',
            isActive: getUserData?.data?.isActive ?? '',
            isDeleted: false,
        })
        setImg(input?.avatar)
        AuthStorage.setStorageData(STORAGEKEY.profileImg, getUserData?.data?.avatar, true);
    }, [getUserData])

    const selectFile = (e: any) => {
        if (e?.target?.files && e?.target?.files[0]) {
            setImg(URL.createObjectURL(e?.target?.files[0]))
        }
    }
    useEffect(() => {
        ApiGet(`qualification/getqualifications?langId=${AuthStorage.getStorageData(STORAGEKEY.language)}`)
            .then((res: any) => {
                if (res && res.data) {
                    setQualification(res.data)
                }
            })
    }, [])

    const handleChange = (e: any, name: any) => {
        if (name === "availableForHire") {
            setInput({ ...input, [name]: e.target.checked })
        }
        else {
            setInput({ ...input, [name]: e.target.value })
            setError({ ...error, [name]: "" })
        }
    }

    const fileUpload = (e: any) => {
        setError({ ...error, companyLogo: "" })
        let extensions = e.target.files[0].type
        let extensionsValidation = ["image/jpeg", 'image/png']
        if (extensionsValidation.includes(extensions)) {
            if (e?.target?.files[0]) {
                setInput({ ...input, [e.target.name]: e?.target?.files[0] })
                setError({ ...error, companyLogo: "" })
            }
        } else {
            setFileError({ ...error, companyLogo: 'Please select valid document file' })
        }
    }

    let validation = () => {
        let flag = false
        let Urlregex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
        const error = {
            name: '',
            phone: '',
            email: '',
            aboutYou: '',
            companyName: '',
            companyLogo: '',
            facebook: '',
            twitter: '',
            linkedIn: '',
            instagram: '',
        }
        if (!input.name) {
            error.name = "Enter your name"
            flag = true
        }
        if (!input.phone) {
            error.phone = "Enter your phone"
            flag = true
        }
        if (!input.email) {
            error.email = "Enter your email"
            flag = true
        }
        if (input.aboutYou) {
            if (input.aboutYou?.length < 50) {
                error.aboutYou = "Review must be at least 50 characters"
                flag = true
            }
        }
        if (userType === "PARTNER" || userType === "EMPLOYER") {
            if (!input.companyName) {
                error.companyName = "Enter your company name"

                flag = true
            }
            if (!input.companyLogo) {
                error.companyLogo = "Select companyLogo"
                flag = true
            }
        }
        if (input.facebook) {
            const testUrl = Urlregex.test(input.facebook)
            if (!testUrl) {
                error.facebook = "Please enter valid url"
                flag = true
            }
        }
        if (input.instagram) {
            const testUrl = Urlregex.test(input.instagram)
            if (!testUrl) {
                error.instagram = "Please enter valid url"
                flag = true
            }
        }
        if (input.linkedIn) {
            const testUrl = Urlregex.test(input.linkedIn)
            if (!testUrl) {
                error.linkedIn = "Please enter valid url"
                flag = true
            }
        }
        if (input.twitter) {
            const testUrl = Urlregex.test(input.twitter)
            if (!testUrl) {
                error.twitter = "Please enter valid url"
                flag = true
            }
        }
        setError(error)
        return flag

    }

    const submit = () => {
        if (validation()) {
            return
        }
        let formData = new FormData();
        formData.append('name', input.name)
        formData.append('phone', input.phone)
        formData.append('email', input.email)
        formData.append('qualification', input.qualification)
        formData.append('avatar', input.avatar)
        formData.append('userType', input.userType)
        formData.append('priority', input.priority ? input.priority : 0)
        formData.append('resumeUrl', input.resumeUrl)
        formData.append('state', input.location)
        formData.append('availableForHire', input.availableForHire);
        formData.append('completeCourse', input.completeCourse)
        formData.append('experience', input.experience)
        formData.append('certification', input.certification)
        formData.append('workExperience', input.workExperience)
        formData.append('selectedJobRole', input.selectedJobRole)
        formData.append('aboutMe', input.aboutYou)
        formData.append('intrested', input.intrested)
        formData.append('facebookLink', input.facebook)
        formData.append('twitterLink', input.twitter)
        formData.append('linkedInLink', input.linkedIn)
        formData.append('instagramLink', input.instagram)
        formData.append('companyName', input.companyName)
        formData.append('companyLogo', input.companyLogo)
        formData.append('isActive', input.isActive);
        formData.append('isDeleted', input.isDeleted);
        ApiPost(`user/auth/editProfile?id=${input.id}`, formData)
            .then((res: any) => {
                if (res.status === 200) {
                    toast.success("Profile updated")
                    if (typeof input.avatar === 'object') {
                        dispatch(IsProfileImage(URL.createObjectURL(input.avatar)))
                    } else {
                        dispatch(IsProfileImage(input.avatar))
                    }
                    if (userType === "USER") {
                        navigate("/myprofile")
                    } else if (userType === "EMPLOYER") {
                        navigate("/employer/myprofile")
                    } else if (userType === "PARTNER") {
                        navigate("/partner/myprofile")
                    }
                }
            })
    }
    const handaleDelete = () => {
        setInput({ ...input, sendEmail: true })
        setDeleteProfile(true)
    }

    return (
        <>
            <div className='my_profile_main'>
                <Container>
                    <h1 className='heading-txt'>{t("editprofile.title")}</h1>
                    <div className='profile-view '>
                        <Row className='border-bottom mb-4'>
                            <Col lg={9}>
                                <div className="d-flex edit-profile flex-wrap">
                                    <div className="file-input">
                                        {img || (AuthStorage.getStorageData(STORAGEKEY.profileImg) && AuthStorage.getStorageData(STORAGEKEY.profileImg) !== "") ? <>
                                            <img src={img ? img : AuthStorage.getStorageData(STORAGEKEY.profileImg)} alt="" className='selected-img' />
                                        </> :
                                            <>
                                                <img src="./assets/img/add-file.png" alt="" />
                                                <label htmlFor="myfile" className='file-input-lable'>{t("editprofile.addphoto")}</label>
                                                <input type="file" id="myfile" name="myfile" onChange={(e) => selectFile(e)} />
                                            </>}

                                    </div>
                                    <div className='profile-detail ms-0 ms-md-4 gap-2'>
                                        <div className='switch-btn-set'>
                                            <FormControlLabel
                                                className='me-0'
                                                label={t("editprofile.avail_hire")}
                                                name="availableForHire"
                                                control={<IOSSwitch sx={{ m: 1 }} />}
                                                defaultValue={input?.availableForHire}
                                                onChange={(e: any) => handleChange(e, "availableForHire")}
                                            />
                                        </div>
                                        <h1 style={{ marginBottom: '0px' }} >{t("editprofile.fileupload")}</h1>
                                        {img || AuthStorage.getStorageData(STORAGEKEY.profileImg) ? <button className='change-photo-btn' onClick={() => { setUploadImageResume(true); setUploadHeader(t("changephotomodal.title")); setUploadPhotoResume(t("changephotomodal.photo")); setUploadtext(t("changephotomodal.fileupload")) }} >{t("editprofile.changephoto")}</button> : ""}
                                        {!(userType === "EMPLOYER" || userType === "PARTNER") ? <button className='' onClick={() => { setUploadResume(true); setUploadHeader(t("resumeuploadmodal.title")); setUploadPhotoResume(t("resumeuploadmodal.resumebtn")); setUploadtext(t("resumeuploadmodal.fileupload")) }} ><img src='./assets/img/Mask (1).png' />{t("editprofile.uploadresume")}</button> : ""}

                                    </div>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className='social-detail-main'>
                                    <div className='social-detail'>
                                        <button className='delete-btn mt-2' onClick={handaleDelete}>{t("editprofile.deletebtn")}</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <h1 className='title mb-4'>{t("editprofile.generals")}</h1>
                        <Row className='border-bottom mb-4'>
                            <Col lg={4} className="mb-4">
                                <TextField className="mi-input" name='name' id="outlined-danger" type="text" value={input.name} label={t("editprofile.name")} variant="outlined" onChange={(e: any) => { handleChange(e, "name"), setError({ ...error, name: '' }) }} />
                                {error?.name && <span style={{ color: "red" }}>{error.name}</span>}
                            </Col>
                            <Col lg={4} className="mb-4">
                                <TextField className="mi-input" name='phone' id="outlined-danger" type="text" value={input.phone} label={t("editprofile.mobile")} variant="outlined" onChange={(e: any) => { handleChange(e, "phone"), setError({ ...error, phone: '' }) }} inputProps={{ maxLength: 10 }} />
                                {error?.phone && <span style={{ color: "red" }}>{error.phone}</span>}
                            </Col>
                            <Col lg={4} className="mb-4">
                                <TextField className="mi-input" name='email' id="outlined-danger" type="text" value={input.email} label={t("editprofile.email")} variant="outlined" onChange={(e: any) => { handleChange(e, "email"), setError({ ...error, email: '' }) }} />
                                {error?.email && <span style={{ color: "red" }}>{error.email}</span>}
                            </Col>
                            <Col lg={4} className="mb-4">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t("editprofile.location")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        name='location'
                                        id="demo-simple-select"
                                        value={input.location}
                                        label="Enter Your Location"
                                        onChange={(e: any) => handleChange(e, "location")}
                                    >
                                        {allStateData && allStateData.data?.map((item: any) => (
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <TextField className="mi-input" name='experience' id="outlined-danger" type="text" value={input.experience} label={t("editprofile.experience")} variant="outlined" onChange={(e: any) => handleChange(e, "experience")} />
                            </Col>
                            <Col lg={4} className="mb-4">
                                <TextField className="mi-input" name='facebook' id="outlined-danger" type="url" value={input.facebook} label={t("editprofile.facebook")} variant="outlined" onChange={(e: any) => handleChange(e, "facebook")} />
                                {error?.facebook && <span style={{ color: "red" }}>{error.facebook}</span>}
                            </Col>
                            <Col lg={4} className="mb-4">
                                <TextField className="mi-input" name='twitter' id="outlined-danger" type="url" value={input.twitter} label={t("editprofile.twitter")} variant="outlined" onChange={(e: any) => handleChange(e, "twitter")} />
                                {error?.twitter && <span style={{ color: "red" }}>{error.twitter}</span>}
                            </Col>
                            <Col lg={4} className="mb-4">
                                <TextField className="mi-input" name='linkedIn' id="outlined-danger" type="url" value={input.linkedIn} label={t("editprofile.linkedin")} variant="outlined" onChange={(e: any) => handleChange(e, "linkedIn")} />
                                {error?.linkedIn && <span style={{ color: "red" }}>{error.linkedIn}</span>}
                            </Col>
                            <Col lg={4} className="mb-4">
                                <TextField className="mi-input" name='instagram' id="outlined-danger" type="url" value={input.instagram} label={t("editprofile.instagram")} variant="outlined" onChange={(e: any) => handleChange(e, "instagram")} />
                                {error?.instagram && <span style={{ color: "red" }}>{error.instagram}</span>}
                            </Col>
                            {userType === "PARTNER" || userType === "EMPLOYER" ? <Col lg={4} className="mb-4">
                                <TextField className="mi-input" name='companyName' id="outlined-danger" type="text" value={input.companyName} label="Enter Your company mame" variant="outlined" onChange={(e: any) => handleChange(e, "companyName")} />
                                {error?.companyName && <span style={{ color: "red" }}>{error.companyName}</span>}
                            </Col> : ""}
                            {userType === "PARTNER" || userType === "EMPLOYER" ? <Col lg={4} className="mb-4">
                                <TextField id="thumbnail" name='companyLogo' type="file" label="Choose company logo"
                                    variant="outlined" onChange={(e) => fileUpload(e)} InputLabelProps={{ shrink: true }} />
                                {error?.companyLogo && <span style={{ color: "red" }}>{error.companyLogo}</span>}
                            </Col> : ""}
                        </Row>
                        <h1 className='title mb-4'>{t("editprofile.qualification")}</h1>
                        <Row className=''>
                            <Col lg={4} className="mb-4">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t("editprofile.edu_qualification")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={input.qualification}
                                        name="qualification"
                                        label="Enter Your Education Qualification"
                                        onChange={(e: any) => handleChange(e, "qualification")}
                                    >
                                        {qualification && qualification?.map((item: any) => (
                                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col lg={4} className="mb-4">
                                <TextField className="mi-input" name='intrested' id="outlined-danger" type="text" value={input.intrested} label={t("editprofile.intrested")} variant="outlined" onChange={(e: any) => handleChange(e, "intrested")} />
                            </Col>
                            <Col lg={12} className="mb-4">
                                <TextField id="outlined-multiline-static" value={input.aboutYou} label={t("editprofile.about")} multiline name='aboutYou' rows={3} onChange={(e: any) => { handleChange(e, "aboutYou"), setError({ ...error, aboutYou: "" }) }} />
                                {error?.aboutYou && <span style={{ color: "red" }}>{error.aboutYou}</span>}

                                <div className="review_form mb-4">
                                    <p>{t("editprofile.review")} <span>{t("editprofile.guidelines")}</span></p>
                                </div>
                                <div className="text-end">
                                    <Button className='save-button' onClick={submit}>{t("editprofile.savebtn")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </Container>
            </div>

            {uploadImageResume &&
                <UploadImageResume
                    show={uploadImageResume}
                    onHide={() => setUploadImageResume(false)}
                    header={uploadHeader}
                    uploadPhotoResume={uploadPhotoResume}
                    uploadtext={uploadtext}
                    setImagesData={(e: any) => { setImg(e), setImg(URL.createObjectURL(e)), setInput({ ...input, avatar: e }) }}
                    profileImage={true}
                    data={input}
                    uploadImage={true}
                />
            }

            {uploadResume &&
                <UploadImageResume
                    show={uploadResume}
                    onHide={() => setUploadResume(false)}
                    header={uploadHeader}
                    uploadPhotoResume={uploadPhotoResume}
                    uploadtext={uploadtext}
                    setImagesData={(e: any) => { setInput({ ...input, resumeUrl: e }) }}
                    profileImage={false}
                    data={input}
                    uploadImage={false}
                />
            }
            {
                deleteProfile &&
                <DeleteProfile
                    show={deleteProfile}
                    onHide={() => setDeleteProfile(false)}
                    input={input}
                />
            }


        </>

    )
}

export default EditProfile
