import { log } from "console";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ApiPost } from "../../helper/API/ApiData";
import { useTranslation } from "react-i18next";

const DeleteProfile = ({ show, onHide, header, input }: any) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const submit = () => {
        let body = {
            'userId': input.id,
            'email': input.email,
            'phone': input.phone,
            'userType': input.userType,
        }
        ApiPost(`user/auth/deleteProfile`, body)
            .then((res: any) => {
                if (res.status === 200) {
                    localStorage.clear();
                    navigate('/');
                    toast.success("Profile deleted")
                }
            })
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter" setLoginType
            centered
            className="upload-image-resume"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>{header}</Modal.Header>
            <Modal.Body>
                <div className="via-checkbox">
                    <div className="text-center">
                        <label htmlFor="female">{t("deletemodal.title")}</label>
                    </div>
                </div>
                <div className="mt-4 text-center">
                    <Button className="submit-btn" onClick={submit}>{t("deletemodal.deletebtn")}</Button>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default DeleteProfile