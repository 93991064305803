import { useState } from "react";
import { Button, Modal, Toast } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ApiPost } from "../../helper/API/ApiData";
import { profileImageAction } from "../../redux/actions/userAction";

const UploadImageResume = ({ show, onHide, header, uploadPhotoResume, uploadtext, setImagesData, profileImage, data, uploadImage }: any) => {
    const dispatch = useDispatch()
    const [image, setImage] = useState<any>()
    const [error, setError] = useState<any>()
    const [imageName, setImageName] = useState<any>()

    const handalChnage = (e: any) => {
        setError('')
        let extensions = e.target.files[0].type
        let extensionsValidation = profileImage ? ["image/jpeg", 'image/png'] : ['image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf']
        if (extensionsValidation.includes(extensions)) {
            if (e.target.files[0].size / 1024 > 3063) {
                setError("File size larger than 3MB")
            }
            else if (e.target.files[0].size / 1024 < 3063 && e?.target?.files && e?.target?.files[0]) {
                setImage(e?.target?.files[0])
                setImageName(e?.target?.files[0].name)
                setError("")
            }
        } else {
            setError('Please select valid document file')
        }
    }

    const submit = () => {
        if (error !== "") {
            return
        }
        let formData = new FormData();
        formData.append('name', data.name)
        formData.append('phone', data.phone)
        formData.append('email', data.email)
        formData.append('qualification', data.qualification)
        formData.append('avatar', image ? image : data.avatar)
        formData.append('userType', data.userType)
        formData.append('priority', data.priority ? data.priority : 0)
        formData.append('resumeUrl', data.resumeUrl)
        formData.append('state', data.location)
        formData.append('availableForHire', data.availableForHire);
        formData.append('completeCourse', data.completeCourse)
        formData.append('experience', data.experience)
        formData.append('certification', data.certification)
        formData.append('workExperience', data.workExperience)
        formData.append('selectedJobRole', data.selectedJobRole)
        formData.append('aboutMe', data.aboutYou)
        formData.append('intrested', data.intrested)
        formData.append('facebookLink', data.facebook)
        formData.append('twitterLink', data.twitter)
        formData.append('linkedInLink', data.linkedIn)
        formData.append('instagramLink', data.instagram)
        formData.append('companyName', data.companyName)
        formData.append('companyLogo', data.companyLogo)
        formData.append('isActive', data.isActive);
        formData.append('isDeleted', data.isDeleted);
        ApiPost(`user/auth/editProfile?id=${data.id}`, formData)
            .then((res: any) => {
                if (res && res.status === 200) {
                    setImagesData(image)
                    onHide()
                    dispatch(profileImageAction(data.userType, image))
                    if (uploadImage) {
                        toast.success("Profile image upload")
                    } else {
                        toast.success("Resume upload")
                    }
                }
            }).catch((error) => {
                return error
            })
    }
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter" setLoginType
            centered
            className="upload-image-resume"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>{header}</Modal.Header>
            <Modal.Body>
                <div className="file-input mb-3">
                    <img src="./assets/img/add-file.png" alt="" />
                    <p>{uploadtext}</p>
                    <label htmlFor="myfile" className='file-input-lable'>{uploadPhotoResume}</label>
                    <input type="file" id="myfile" name="myfile" onChange={(e: any) => handalChnage(e)} />
                    {error && <span style={{ color: "red" }}>{error}</span>}
                    {!error && <span style={{ color: "green" }}>{imageName}</span>}
                </div>
                <div className="mt-4 text-center">
                    <Button className="submit-btn" onClick={submit}>Submit</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UploadImageResume