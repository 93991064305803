
import { log } from 'console';
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import AddLanduage from '../components/modals/AddLanguage';
import { HINDI_ID, MARATHI_ID } from '../config/API/api.config';
import STORAGEKEY from '../config/APP/app.config';
import { ApiDelete, ApiGet, ApiPost } from '../helper/API/ApiData';
import AuthStorage from '../helper/AuthStorage';
import { deletePartnerCourse, editActivePartnerCourse, getPatnerCourses } from '../redux/actions/ActiveCourseAction';
import { editPartnerCourse } from '../redux/actions/courseAction';
interface Props {
    img: string,
    title: string,
    duration?: string,
    vacancy_txt: string,
    calendar_txt: string,
    active_job_btn?: boolean,
    InactiveJobs_btn?: boolean,
    coursesData?: any,
    addToMultiLanguage?: any,
    sendEmail?: any
    responceData?: any,
}

const CourseCard: FC<Props> = ({ img, title, duration, vacancy_txt, calendar_txt, active_job_btn, InactiveJobs_btn, coursesData, addToMultiLanguage, sendEmail, responceData }) => {

    const { t } = useTranslation()
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [languageConfirm, setLanguageConfirm] = useState(false)
    const [coursesDetail, setCoursesDetail] = useState<any>({})


    const activeCourses = (courseKey: string) => {
        swal({
            title: "Are you sure?",
            text: "you want to Reactive it?",
            icon: "success",
            buttons: ["cancel", "ok"],
            dangerMode: false,
        }).then((result) => {
            if (result) {
                ApiPost(`course/reactivePartnerCourseByKey?key=${courseKey}`, {})
                    .then((res: any) => {
                        responceData(res)
                    }).catch((error) => { return error })

            }
        })
    }

    const deActiveCourses = (courseKey: string) => {
        swal({
            title: "Are you sure?",
            text: "you want to Deactive it?",
            icon: "error",
            buttons: ["cancel", "ok"],
            dangerMode: true,
        }).then((result) => {
            if (result) {
                // dispatch(deletePartnerCourse(coursesData.id, coursesData.key, 'single', coursesData.courseCategory.id))
                ApiDelete(`course/deletePartnerCourseByKey?key=${courseKey}`, {})
                    .then((res: any) => {
                        if (res.status === 200) {
                            responceData(res)
                        }
                    })
                    .catch((error) => { return error })
            }
        })
    }

    const editCourse = () => {
        navigate(`/partner/postcourses?id=${coursesData.id}&type=${"active"}`)
    }

    const viewCourse = () => {
        navigate(`/partner/viewpartnercourse?id=${coursesData.id}`)
    }

    const editCourseInactive = () => {
        navigate(`/partner/postcourses?id=${coursesData.id}&type=${"inactive"}`)
    }

    const addCourses = (courseskey: string, lanId: string, addIn: string) => {
        setLanguageConfirm(true)
        setCoursesDetail({ key: courseskey, lanId: lanId, addIn: addIn })
    }
    const editCourses = (courseskey: any, languageId: any, addIn: string) => {
        ApiGet(`course/getOneCourseDetailByKey?langId=${languageId}&key=${courseskey}`)
            .then((res: any) => {
                if (res?.status === 200) {
                    navigate(`/partner/postcourses?id=${res?.data?.id}&lanId=${languageId}&addIn=${addIn}`)
                }
            })
            .catch((e) => {
                return e
            })
    }

    return (
        <>
            <div className="acquire-card" style={{ height: "auto" }}>
                <div style={{ 'height': '111px' }}>
                    <figure className="m-0 text-center">
                        <img
                            className="mainImg"
                            style={{ height: "111px", width: "24%" }}
                            src={'../../../assets/img/logo.png'}
                            alt="card-img"
                        />
                    </figure>
                </div>
                <div className="details job-card-detail">
                    <div className="details-title">
                        <h1>{title}</h1>
                    </div>
                    <div className='d-flex align-items-center mb-3 text-align: center'>
                        <img src="../../../assets/img/duration.png" alt="" />
                        <p className="text mb-0 ms-3  text-ellips" style={{ height: "auto" }}>
                            {duration}
                        </p>
                    </div>
                    <div className='d-flex align-items-center mb-3 text-align: center'>
                        <img src="../../../assets/img/category.png" alt="" />
                        <p className="text mb-0 ms-3 text-ellips">
                            {vacancy_txt}
                        </p>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                        <img src="../../../assets/img/calendar.png" alt="" style={{ width: "15px", height: "16px" }} />
                        <p className="text mb-0 ms-3 text-ellips">
                            {calendar_txt}
                        </p>
                    </div>
                </div>
                {addToMultiLanguage &&
                    <div className='InactiveJobs_btn' style={{ display: 'flex', gap: '10px', padding: '10px' }}>
                        <button className={coursesData.hindi ? 'jobCard_add' : 'jobCard_notAdd'} onClick={() => coursesData.hindi ? editCourses(coursesData.key, HINDI_ID, 'hindi') : addCourses(coursesData.key, HINDI_ID, 'hindi')} >HN</button>
                        <button className={coursesData.marathi ? 'jobCard_add' : 'jobCard_notAdd'} onClick={() => coursesData.marathi ? editCourses(coursesData.key, MARATHI_ID, 'marathi') : addCourses(coursesData.key, MARATHI_ID, 'marathi')} >MT</button>
                    </div>
                }
                {
                    active_job_btn && <div className='d-flex btn-content-jobcard' style={{ width: "100%" }}>
                        <button className='View_Applicants_btn' onClick={() => viewCourse()}>View course</button>
                        <button className='Edit_btn' onClick={() => editCourse()}>Edit</button>
                        <button className='Deactivate_btn' onClick={() => deActiveCourses(coursesData.key)}>Deactive</button>
                    </div>
                }
                {
                    InactiveJobs_btn &&
                    <div className='InactiveJobs_btn'>
                        <button className='Edit_btn' style={{ width: `${!coursesData.isAdminApproved && '100%'}` }} onClick={() => editCourseInactive()}>Edit</button>
                        {coursesData.isAdminApproved && <button className='Deactivate_btn' onClick={() => activeCourses(coursesData.key)}>Re-active</button>}
                    </div>
                }
                {languageConfirm && <AddLanduage show={languageConfirm} onHide={() => setLanguageConfirm(false)} keyId={coursesDetail.key} lanId={coursesDetail.lanId} addIn={coursesDetail.addIn} addInCourses={true} />}
            </div>
        </>
    )
}

export default CourseCard