import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Container } from 'react-bootstrap';
import { Divider, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardJobs } from '../../redux/actions/jobAction';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NoDataFound from '../../common/NoDataFound';
import Share from '../../helper/sharer/Share';
import { FiShare2 } from 'react-icons/fi';
interface Filter {
  search: string,
  location: string,
}

const Dashboard = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [pageNo, setPageNo] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(5)
  const [filter, setFilter] = useState<Filter>({
    search: '',
    location: '',
  })
  const [dashboardJob, setDashboardJob] = useState<any[]>([])
  const [dashboardJobCount, setDashboardJobCount] = useState<any>()

  const dashboardJobsData = useSelector((state: any) => state.jobData.dashboardJobs)

  useEffect(() => {
    dispatch(dashboardJobs(perPage, pageNo))
  }, [])

  useEffect(() => {
    if (dashboardJobsData && dashboardJobsData.data && dashboardJobsData.data.data && dashboardJobsData.data.data.length > 0) {
      setDashboardJob(dashboardJobsData.data.data)
    }
    if (dashboardJobsData && dashboardJobsData.data) {
      setDashboardJobCount(dashboardJobsData.data)
    }
  }, [dashboardJobsData])

  const TEMP = [
    {
      name: 'General Duty Assistant Mumbai',
      active_app: 120,
      new_app: 10,
      shhortlisted: 10,
      hired: 10,
    },
    {
      name: 'Assistant Nurse Thane',
      active_app: 120,
      new_app: 10,
      shhortlisted: 10,
      hired: 10,
    },
    {
      name: 'Lab Technician Mumbai',
      active_app: 120,
      new_app: 10,
      shhortlisted: 10,
      hired: 10,
    },
  ]

  function createData(name: string, Active_applicants: any, New_applicants: any, Shortlisted: any, Hired_Candidate: any,) {
    return { name, Active_applicants, New_applicants, Shortlisted, Hired_Candidate, };
  }

  const rows = TEMP.map((i) => createData(i.name, i.active_app, i.new_app, i.shhortlisted, i.hired))


  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  const progressBarData = [
    {
      titel: t("Employee.dashboard.progressTable.generalDutyAssistant"),
      value: 15,
      No_Of_app: `15 ${t("Employee.dashboard.progressTable.progressApp")}`
    },
    {
      titel: t("Employee.dashboard.progressTable.UIDesigner"),
      value: 10,
      No_Of_app: `10 ${t("Employee.dashboard.progressTable.progressApp")}`
    },
    {
      titel: t("Employee.dashboard.progressTable.assistantNurset"),
      value: 35,
      No_Of_app: `35 ${t("Employee.dashboard.progressTable.progressApp")}`
    },
    {
      titel: t("Employee.dashboard.progressTable.labTechnician"),
      value: 5,
      No_Of_app: `5 ${t("Employee.dashboard.progressTable.progressApp")}`
    },
  ]


  const [temp, setTemp] = useState(Number)
  const getsum = () => {
    let totals = 0
    for (let i = 0; i < progressBarData.length; i++) {
      totals += progressBarData[i].value;
    }
    return setTemp(totals)
  }

  useEffect(() => {
    getsum()
  }, [])

  const message = `Hey, Are you looking for Job? Swayam will really help you get job, here the link of job please click on this link ${window.location.origin}`

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className='my_profile_main' >
        <Container fluid>

          <div className='deshboard-header d-flex flex-wrap justify-content-between align-items-center mb-5' >
            <h1 className='heading-txt m-3'>{t("Employee.dashboard.title")}</h1>
            <div className='d-flex flex-wrap position-relative'>
              <button className='Post_Job m-3' onClick={() => navigate('/employer/postjob')}>{t("Employee.dashboard.btn.postAJob")}</button>
            </div>
          </div>
          <div>
            <Row>
              <Col lg={2}>
                <div className='courses-count-box'>
                  <h4>{dashboardJobCount?.allJobCount}</h4>
                  <h6>All Job Count</h6>
                </div>
              </Col>
              <Col lg={2}>
                <div className='courses-count-box'>
                  <h4>{dashboardJobCount?.activeJobCount}</h4>
                  <h6>Active Job Count</h6>
                </div>
              </Col>
              <Col lg={2}>
                <div className='courses-count-box'>
                  <h4>{dashboardJobCount?.inactiveJobCount}</h4>
                  <h6>Inactive Job Conunt</h6>
                </div>
              </Col>
            </Row>
          </div>
          <div className='deshboard-main mt-3' style={{ overflowY: "auto" }}>
            <h1 className='heading-txt m-0 ps-3 mb-3 mt-3' style={{ padding: '15px' }}>{t("Employee.dashboard.table.title")}</h1>
            <div className='top_job_table'>
              {dashboardJob.length > 0 &&
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("Employee.dashboard.table.label.jobTitle")}</TableCell>
                      <TableCell align="right">{t("Employee.dashboard.table.label.activeApp")}</TableCell>
                      <TableCell align="right">{t("Employee.dashboard.table.label.shortlisted")}</TableCell>
                      <TableCell align="right">{t("Employee.dashboard.table.label.hiredCand")}</TableCell>
                      <TableCell align="right">{t("Employee.dashboard.table.label.jobStatus")}</TableCell>
                      <TableCell align="right">{t("Employee.dashboard.table.label.action")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='text-center'>
                    {dashboardJob.length > 0 && dashboardJob.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align='left' scope="row">{row.description}</TableCell>
                        <TableCell>{row.all_applicants}</TableCell>
                        <TableCell>{row.selected_applicants}</TableCell>
                        <TableCell>{row.hired_applicants}</TableCell>
                        <TableCell className='text-center'>{'Open'}</TableCell>
                        <TableCell align="right">
                          <Tooltip title="Account settings">
                            <IconButton
                              onClick={handleClick}
                              size="small"
                              sx={{ ml: 2 }}
                              aria-controls={open ? 'account-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                            >
                              <FiShare2 style={{ color: 'rgb(201, 15, 34)', fontSize: '22px' }} />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.12))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                '&:before': {
                                  content: '""',
                                  display: 'block',
                                  position: 'absolute',
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: 'background.paper',
                                  transform: 'translateY(-50%) rotate(45deg)',
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                          >
                            <MenuItem onClick={() => Share('facebook', `${message}/employer/dashboard?jobId${row.id}`)}>
                              <img src="../../assets/img/connect-facebook.png" alt="facebook" width="12px" style={{ marginRight: '20px' }} />
                              {t("jobs.share.facebook")}
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => Share('linkedin', `${message}/employer/dashboard?jobId${row.id}`)}>
                              <img src="../../assets/img/linkedin.png" alt="Linkedin" width="20px" style={{ marginRight: '20px' }} />
                              <span>{t("jobs.share.linkedin")}</span>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => Share('twitter', `${message}/employer/dashboard?jobId${row.id}`)}>
                              <img src="../../assets/img/twitter.png" alt="Twitter" width="20px" style={{ marginRight: '20px' }} />
                              <span >{t("jobs.share.twitter")}</span>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => Share('whatsapp', `${message}/employer/dashboard?jobId${row.id}`)}>
                              <img src="../../assets/img/whatsapp.png" alt="WhatsApp" width="20px" style={{ marginRight: '20px' }} />
                              <span >{t("jobs.share.whatsApp")}</span>
                            </MenuItem>
                          </Menu>


                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              }
              {
                dashboardJob.length === 0 &&
                <div style={{ textAlign: 'center' }}>
                  <NoDataFound text="No data found" />
                </div>
              }
            </div>
          </div>

          <div className='deshboard-main p-4 progress_bar_main mt-5'>
            <div className='d-flex progress_bar flex-wrap justify-content-between mb-4'>
              <div className='progress_bar_title'>
                <h1>{t("Employee.dashboard.progressTable.title")}</h1>
                <p>{temp} {t("Employee.dashboard.progressTable.subTitle")}</p>
              </div>
            </div>
            {
              progressBarData.map((item) => (
                <Row className='align-items-center'>
                  <Col lg={2}>
                    <div className='jop_type_name'>
                      <h1>{item.titel}</h1>
                    </div>
                  </Col>
                  <Col lg={8}>
                    <Box sx={{ flexGrow: 1 }}>
                      <BorderLinearProgress variant="determinate" value={item.value} className="progress-bar-content" />
                    </Box>
                  </Col>
                  <Col lg={2}>
                    <h2>{item.No_Of_app}</h2>
                  </Col>
                </Row>
              ))
            }
          </div>
        </Container>
      </div >
    </>
  )
}

export default Dashboard