import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import { BiArrowBack } from 'react-icons/bi'

const PageNotFound = () => {
    const navigate = useNavigate()

    return (
        <div className="w-100 vh-100 d-flex justify-content-center align-items-center flex-column">
            <h2>404 Error</h2>
            <h2>Page Not Found</h2>
            <Button variant="outlined" onClick={() => navigate(-1)}> <BiArrowBack />&nbsp; Back</Button>
        </div>
    );
}

export default PageNotFound;