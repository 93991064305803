import { useNavigate } from 'react-router'
import Profiling_Two_Color from '../../assets/img/Profiling_Two Color.png'
import STORAGEKEY from '../../config/APP/app.config'
import AuthStorage from '../../helper/AuthStorage'

const InactiveEmployer = () => {
    const userType = AuthStorage.getStorageData(STORAGEKEY.userType)
    const navigate = useNavigate()
    return (

        <div className="" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            flexDirection: 'column',
            height: '80vh',
            textAlign: 'center'
        }}>
            <img src={Profiling_Two_Color} alt="" />
            <h4 style={{ fontSize: '28px', color: 'var(--blue)', fontWeight: '700' }}>Your registration request is received and under evaluation. <br /> We will get back to you soon.</h4>
            <button onClick={() => navigate(userType === "EMPLOYER" ? '/employer/edit-employer-profile' : userType === "PARTNER" ? '/partner/edit-partner-profile' : '')} className="load-more">Edit profile</button>
        </div>
    )
}

export default InactiveEmployer