import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import JonApplicationModal from '../components/modals/JonApplicationModal'
import swal from 'sweetalert'
import { HINDI_ID, MARATHI_ID } from '../config/API/api.config'
import AddLanduage from '../components/modals/AddLanguage'
import { ApiDelete, ApiGet, ApiPost } from '../helper/API/ApiData'
interface Props {
    img: string,
    title: string,
    location_txt: string,
    vacancy_txt: string,
    calendar_txt: string,
    active_job_btn?: boolean,
    InactiveJobs_btn?: boolean,
    jobData?: any,
    addToMultiLanguage?: any,
    responceData?: any
}

const JobCard: FC<Props> = ({ img, title, location_txt, vacancy_txt, calendar_txt, active_job_btn, InactiveJobs_btn, jobData, addToMultiLanguage, responceData }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [jobApplicationModal, setJobApplicationModal] = useState(false)
    const [bodyData, setBodyData] = useState({})
    const [languageConfirm, setLanguageConfirm] = useState(false)
    const [jobDetail, setJobDetail] = useState<any>({})

    useEffect(() => {
        setBodyData({
            ...bodyData,
            name: jobData.name.id,
            state: jobData.state.id,
            district: jobData.district.id,
            town: jobData.town,
            pincode: jobData.pincode,
            description: jobData.description,
            vacancies: jobData.vacancies,
            reqExperience: jobData.reqExperience,
            salary: jobData.salary,
            benifits: jobData.benifits,
            requirements: jobData.requirements,
            type: jobData.type,
            extraType: jobData.extraType,
            shifts: jobData.shifts,
            email: jobData.email,
            phone: jobData.phone,
            startDate: jobData.startDate,
            endDate: jobData.endDate,
            jobRole: jobData.jobRole.id,
            jobType: jobData.jobType.id,
            application_form: jobData.application_form,
            application_process: jobData.application_process,
            hospital_expenses_estimation_certificate: jobData.hospital_expenses_estimation_certificate,
            medical_superintendent: jobData.medical_superintendent,
            recommended_and_forwarded: jobData.recommended_and_forwarded,
            sendEmail: true,
        })
    }, [jobData])

    const handalReActive = (jobkey: any) => {
        swal({
            title: "Are you sure?",
            text: "you want to Reactive it?",
            icon: "success",
            buttons: ["cancel", "ok"],
            dangerMode: false,
        }).then((result) => {
            if (result) {
                ApiPost(`job/reActiveJobByKey?key=${jobkey}`, {})
                    .then((res: any) => {
                        responceData(res)
                    }).catch((error) => {
                        return error
                    })
            }
        })
    }

    const handalDeActive = (jobkey: any) => {
        swal({
            title: "Are you sure?",
            text: "you want to Deactive it?",
            icon: "error",
            buttons: ["cancel", "ok"],
            dangerMode: true,
        }).then((result) => {
            if (result) {
                ApiDelete(`job/deleteJobByKey?key=${jobkey}`, {})
                    .then((res: any) => {
                        responceData(res)
                    })
            }
        })
    }
    const handalEdit = (id: any) => {
        navigate(`/employer/postjob?id=${id}`)
    }
    const handalViewApp = (id: any) => {
        navigate(`/employer/jobapplication?id=${id}`)
        setJobApplicationModal(true)
    }
    const addJob = (jobkey: string, lanId: string, addIn: string) => {
        setLanguageConfirm(true)
        setJobDetail({ key: jobkey, lanId: lanId, addIn: addIn })
    }
    const editJob = (jobKey: any, languageId: any, addIn: string) => {
        ApiGet(`job/getJobByKey?langId=${languageId}&key=${jobKey}`)
            .then((res: any) => {
                if (res?.status === 200) {
                    navigate(`/employer/postjob?id=${res?.data?.id}&lanId=${languageId}&addIn=${addIn}`)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    return (
        <>
            <div className="acquire-card" style={{ height: "auto", maxWidth: '410px', margin: 'auto' }}>
                <div style={{ 'height': '111px' }}>
                    <figure className="m-0 text-center">
                        <img
                            className="mainImg"
                            style={{ height: "111px", width: "24%" }}
                            src={'../../../assets/img/logo.png'}
                            alt="card-img"
                        />
                    </figure>
                </div>
                <div className="details job-card-detail">
                    <div className="details-title">
                        <h1>{title}</h1>
                    </div>
                    <div className='d-flex align-items-center mb-3 text-align: center'>
                        <img src="../../../assets/img/location-pin.png" alt="" style={{ width: "16px", height: "20px" }} />
                        <p className="text mb-0 ms-3" style={{ height: "auto" }}>
                            {location_txt}
                        </p>
                    </div>
                    <div className='d-flex align-items-center mb-3 text-align: center'>
                        <img src="../../../assets/img/noun-vacancy-3194977 (1).png" alt="" style={{ width: "15px", height: "20px" }} />
                        <p className="text mb-0 ms-3">
                            {vacancy_txt}
                        </p>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                        <img src="../../../assets/img/calendar.png" alt="" style={{ width: "15px", height: "16px" }} />
                        <p className="text mb-0 ms-3">
                            {calendar_txt}
                        </p>
                    </div>
                </div>
                {addToMultiLanguage &&
                    <div className='InactiveJobs_btn' style={{ display: 'flex', gap: '10px', padding: '10px' }}>
                        <button className={jobData.hindi ? 'jobCard_add' : 'jobCard_notAdd'} onClick={() => jobData.hindi ? editJob(jobData.key, HINDI_ID, 'hindi') : addJob(jobData.key, HINDI_ID, "hindi")} >HN</button>
                        <button className={jobData.marathi ? 'jobCard_add' : 'jobCard_notAdd'} onClick={() => jobData.marathi ? editJob(jobData.key, MARATHI_ID, 'marathi') : addJob(jobData.key, MARATHI_ID, 'marathi')} >MT</button>
                    </div>
                }
                {active_job_btn &&
                    <div className='d-flex btn-content-jobcard' style={{ width: "100%" }}>
                        <button className='View_Applicants_btn' onClick={() => handalViewApp(jobData?.id)}>{t("Employee.JobCard.action.viewApp")} </button>
                        <button className='Edit_btn' onClick={() => handalEdit(jobData?.id)}>{t("Employee.JobCard.action.edit")}</button>
                        <button className='Deactivate_btn' onClick={() => handalDeActive(jobData?.key)}>{t("Employee.JobCard.action.deActive")}</button>
                    </div>
                }
                {InactiveJobs_btn &&
                    <div className='InactiveJobs_btn'>
                        <button className='Edit_btn' style={{ width: `${!jobData.isAdminApproved && '100%'}` }} onClick={() => handalEdit(jobData.id)}>{t("Employee.JobCard.action.edit")}</button>
                        {jobData.isAdminApproved && <button className='Deactivate_btn' onClick={() => handalReActive(jobData.key)}>{t("Employee.JobCard.action.reActive")}</button>}
                    </div>
                }
            </div>
            {jobApplicationModal && <JonApplicationModal show={jobApplicationModal} jobId={jobData?.id} onHide={() => setJobApplicationModal(false)} />}
            {languageConfirm && <AddLanduage show={languageConfirm} onHide={() => setLanguageConfirm(false)} keyId={jobDetail.key} lanId={jobDetail.lanId} addIn={jobDetail.addIn} />}
        </>
    )
}

export default JobCard