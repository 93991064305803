export const IS_LOADING = "IS_LOADING"
export const IS_PROFILEIMAGE = "IS_PROFILEIMAGE"

//--- Login ---
export const LOGIN = "LOGIN"
export const LOGIN_LOADING = "LOGIN_LOADING"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const IS_LOGIN = "IS_LOGIN"

export const GET_USER = "GET_USER"
export const GET_USER_LOADING = "GET_USER_LOADING"
export const GET_USER_ERROR = "GET_USER_ERROR"


///--- forgot password ---
export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const FORGOT_PASSWORD_LOADING = "FORGOT_PASSWORD_LOADING"
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR"


///--- forgot password ---
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING"
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR"

///--- change password ---
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD"
export const CHANGE_USER_PASSWORD_LOADING = "CHANGE_USER_PASSWORD_LOADING"
export const CHANGE_USER_PASSWORD_ERROR = "CHANGE_USER_PASSWORD_ERROR"

///--- about us ---
export const GET_ABOUT_US = "GET_ABOUT_US"
export const GET_ABOUT_US_LOADING = "GET_ABOUT_US_LOADING"
export const GET_ABOUT_US_ERROR = "GET_ABOUT_US_ERROR"

export const ADD_ABOUT_US = "ADD_ABOUT_US"
export const ADD_ABOUT_US_LOADING = "ADD_ABOUT_US_LOADING"
export const ADD_ABOUT_US_ERROR = "ADD_ABOUT_US_ERROR"

export const EDIT_ABOUT_US = "EDIT_ABOUT_US"
export const EDIT_ABOUT_US_LOADING = "EDIT_ABOUT_US_LOADING"
export const EDIT_ABOUT_US_ERROR = "EDIT_ABOUT_US_ERROR"

export const GET_WOMEN_TESTIMONIAL = "GET_WOMEN_TESTIMONIAL"
export const GET_WOMEN_TESTIMONIAL_LOADING = "GET_WOMEN_TESTIMONIAL_LOADING"
export const GET_WOMEN_TESTIMONIAL_ERROR = "GET_WOMEN_TESTIMONIAL_ERROR"

///--- signup ---
export const SIGNUP = 'SIGNUP'
export const SIGNUP_LOADING = 'SIGNUP_LOADING'
export const SIGNUP_ERR = 'SIGNUP_ERR'

export const USER_AUTH_SIGNUP = 'USER_AUTH_SIGNUP'
export const USER_AUTH_SIGNUP_LOADING = 'USER_AUTH_SIGNUP_LOADING'
export const USER_AUTH_SIGNUP_ERR = 'USER_AUTH_SIGNUP_ERR'


//---  coures --- 
export const GET_COURSE = " GET_COURSE "
export const GET_COURSE_LOADING = "GET_COURSE_LOADING"
export const GET_COURSE_ERROR = "GET_COURSE_ERROR"

export const GET_COURSES_FOR_SAVE_AND_ENROLLED = " ET_COURSES_FOR_SAVE_AND_ENROLLED "
export const GET_COURSES_FOR_SAVE_AND_ENROLLED_LOADING = "GET_COURSES_FOR_SAVE_AND_ENROLLED_LOADING"
export const GET_COURSES_FOR_SAVE_AND_ENROLLED_ERROR = "GET_COURSES_FOR_SAVE_AND_ENROLLED_ERROR"

export const GET_BANNER_TRUE = "GET_BANNER_TRUE"
export const GET_BANNER_TRUE_LOADING = "GET_BANNER_TRUE_LOADING"
export const GET_BANNER_TRUE_ERROR = "GET_BANNER_TRUE_ERROR"

export const GET_BANNER_FALSE = "GET_BANNER_FALSE"
export const GET_BANNER_FALSE_LOADING = "GET_BANNER_FALSE_LOADING"
export const GET_BANNER_FALSE_ERROR = "GET_BANNER_FALSE_ERROR"

export const GET_OFFLINE_COURSES = "GET_OFFLINE_COURSES"
export const GET_OFFLINE_COURSES_LOADING = "GET_OFFLINE_COURSES_LOADING"
export const GET_OFFLINE_COURSES_ERROR = "GET_OFFLINE_COURSES_ERROR"

export const GET_ONLINE_COURSES = "GET_ONLINE_COURSES"
export const GET_ONLINE_COURSES_LOADING = "GET_ONLINE_COURSES_LOADING"
export const GET_ONLINE_COURSES_ERROR = "GET_ONLINE_COURSES_ERROR"

export const GET_COURSE_CATEGORY = "GET_COURSE_CATEGORY_BEGINE"
export const GET_COURSE_CATEGORY_LOADING = "GET_COURSE_CATEGORY_LOADING"
export const GET_COURSE_CATEGORY_ERROR = "GET_COURSE_CATEGORY_ERROR"

export const UPDATE_COURSE_VIEW_COUNT = "UPDATE_COURSE_VIEW_COUNT"
export const UPDATE_COURSE_VIEW_COUNT_LOADING = "UPDATE_COURSE_VIEW_COUNT_LOADING"
export const UPDATE_COURSE_VIEW_COUNT_ERROR = "UPDATE_COURSE_VIEW_COUNT_ERROR"

export const GET_COURSE_CATEGORIES = "GET_COURSE_CATEGORIES"
export const GET_COURSE_CATEGORIES_LOADING = "GET_COURSE_CATEGORIES_LOADING"
export const GET_COURSE_CATEGORIES_ERROR = "GET_COURSE_CATEGORIES_ERROR"

export const GET_COURSES = "GET_COURSES"
export const GET_COURSES_LOADING = "GET_COURSES_LOADING"
export const GET_COURSES_ERROR = "GET_COURSES_ERROR"

export const GET_COURSES_BY_ID = "GET_COURSES_BY_ID"
export const GET_COURSES_BY_ID_LOADING = "GET_COURSES_BY_ID_LOADING"
export const GET_COURSES_BY_ID_ERROR = "GET_COURSES_BY_ID_ERROR"

export const EDIT_PARTNER_COURSE = "EDIT_PARTNER_COURSE"
export const EDIT_PARTNER_COURSE_LOADING = "EDIT_PARTNER_COURSE_LOADING"
export const EDIT_PARTNER_COURSE_ERROR = "EDIT_PARTNER_COURSE_ERROR"

export const EDIT_ACTIVE_PARTNER_COURSE = "EDIT_ACTIVE_PARTNER_COURSE"
export const EDIT_ACTIVE_PARTNER_COURSE_LOADING = "EDIT_ACTIVE_PARTNER_COURSE_LOADING"
export const EDIT_ACTIVE_PARTNER_COURSE_ERROR = "EDIT_ACTIVE_PARTNER_COURSE_ERROR"

export const GET_ACTIVE_COURSE_FILTER_FOR_MAIN = "GET_ACTIVE_COURSE_FILTER_FOR_MAIN"
export const GET_ACTIVE_COURSE_FILTER_FOR_MAIN_LOADING = "GET_ACTIVE_COURSE_FILTER_FOR_MAIN_LOADING"
export const GET_ACTIVE_COURSE_FILTER_FOR_MAIN_ERROR = "GET_ACTIVE_COURSE_FILTER_FOR_MAIN_ERROR"

export const DELETE_PARTNER_COURSE = "DELETE_PARTNER_COURSE"
export const DELETE_PARTNER_COURSE_LOADING = "DELETE_PARTNER_COURSE_LOADING"
export const DELETE_PARTNER_COURSE_ERROR = "DELETE_PARTNER_COURSE_ERROR"


export const GET_ACTIVE_COURSE_FILTER_FOR_MAIN_TRUE = "GET_ACTIVE_COURSE_FILTER_FOR_MAIN_TRUE"
export const GET_ACTIVE_COURSE_FILTER_FOR_MAIN_FALSE = "GET_ACTIVE_COURSE_FILTER_FOR_MAIN_FALSE"

export const GET_OTHER_COURSES = "GET_OTHER_COURSES"
export const GET_OTHER_COURSES_LOADING = "GET_OTHER_COURSES_LOADING"
export const GET_OTHER_COURSES_ERROR = "GET_OTHER_COURSES_ERROR"

export const GET_QUALIFICATIONS = "GET_QUALIFICATIONS"
export const GET_QUALIFICATIONS_LOADING = "GET_QUALIFICATIONS_LOADING"
export const GET_QUALIFICATIONS_ERROR = "GET_QUALIFICATIONS_ERROR"


//--- Enrolled Course ---
export const ADD_ENROLLED_COURSE = "ADD_ENROLLED_COURSE"
export const ADD_ENROLLED_COURSE_LOADING = "ADD_ENROLLED_COURSE_LOADING"
export const ADD_ENROLLED_COURSE_ERROR = "ADD_ENROLLED_COURSE_ERROR"

export const DELETE_ENROLLED_COURSE = "DELETE_ENROLLED_COURSE"
export const DELETE_ENROLLED_COURSE_LOADING = "DELETE_ENROLLED_COURSE_LOADING"
export const DELETE_ENROLLED_COURSE_ERROR = "DELETE_ENROLLED_COURSE_ERROR"

// --- Saved Course ---
export const ADD_SAVE_COURSE = "ADD_SAVE_COURSE"
export const ADD_SAVE_COURSE_LOADING = "ADD_SAVE_COURSE_LOADING"
export const ADD_SAVE_COURSE_ERROR = "ADD_SAVE_COURSE_ERROR"

export const GET_SAVE_COURSE = "GET_SAVE_COURSE"
export const GET_SAVE_COURSE_LOADING = "GET_SAVE_COURSE_LOADING"
export const GET_SAVE_COURSE_ERROR = "GET_SAVE_COURSE_ERROR"

export const DELETE_SAVE_COURSE = "DELETE_SAVE_COURSE"
export const DELETE_SAVE_COURSE_LOADING = "DELETE_SAVE_COURSE_LOADING"
export const DELETE_SAVE_COURSE_ERROR = "DELETE_SAVE_COURSE_ERROR"

//---  course Rating -- 
export const GET_COURSE_RATING = "GET_COURSE_RATING"
export const GET_COURSE_RATING_LOADING = "GET_COURSE_RATING_LOADING"
export const GET_COURSE_RATING_ERROR = "GET_COURSE_RATING_ERROR"

export const GET_SPECIFIC_COURSE_RATINGS = "GET_SPECIFIC_COURSE_RATINGS"
export const GET_SPECIFIC_COURSE_RATINGS_LOADING = "GET_SPECIFIC_COURSE_RATINGS_LOADING"
export const GET_SPECIFIC_COURSE_RATINGS_ERROR = "GET_SPECIFIC_COURSE_RATINGS_ERROR"

export const ADD_COURSE_RATING = "ADD_COURSE_RATING"
export const ADD_COURSE_RATING_LOADING = "ADD_COURSE_RATING_LOADING"
export const ADD_COURSE_RATING_ERROR = "ADD_COURSE_RATING_ERROR"

export const UPDATE_COURSE = "UPDATE_COURSE"
export const UPDATE_COURSE_LOADING = "UPDATE_COURSE_LOADING"
export const UPDATE_COURSE_ERROR = "UPDATE_COURSE_ERROR"

export const ADD_PARTNER_COURSE = "ADD_PARTNER_COURSE"
export const ADD_PARTNER_COURSE_LOADING = "ADD_PARTNER_COURSE_LOADING"
export const ADD_PARTNER_COURSE_ERROR = "ADD_PARTNER_COURSE_ERROR"


export const GET_COURSE_MODULES = "GET_COURSE_MODULES"
export const GET_COURSE_MODULES_LOADING = "GET_COURSE_MODULES_LOADING"
export const GET_COURSE_MODULES_ERROR = "GET_COURSE_MODULES_ERROR"



//--- job -- 
// export const GET_JOB_CATEGORIES = "GET_JOB_CATEGORIES"
// export const GET_JOB_CATEGORIES_LOADING = "GET_JOB_CATEGORIES_LOADING"
// export const GET_JOB_CATEGORIES_ERROR = "GET_JOB_CATEGORIES_ERROR"

// export const GET_JOB_CATEGORY_BY_NAME = "GET_JOB_CATEGORY_BY_NAME"
// export const GET_JOB_CATEGORY_BY_NAME_LOADING = "GET_JOB_CATEGORY_BY_NAME_LOADING"
// export const GET_JOB_CATEGORY_BY_NAME_ERROR = "GET_JOB_CATEGORY_BY_NAME_ERROR"

export const POST_ALL_JOB = " POST_ALL_JOB "
export const POST_ALL_JOB_LOADING = "POST_ALL_JOB_LOADING "
export const POST_ALL_JOB_ERROR = " POST_ALL_JOB_ERROR"

export const POST_UPDATE_JOB_VIEWCOUNT = "POST_UPDATE_JOB_VIEWCOUNT  "
export const POST_UPDATE_JOB_VIEWCOUNT_LOADING = "POST_UPDATE_JOB_VIEWCOUNT_LOADING "
export const POST_UPDATE_JOB_VIEWCOUNT_ERROR = " POST_UPDATE_JOB_VIEWCOUNT_ERROR"

export const GET_JOB_BY_ID = "GET_JOB_BY_ID "
export const GET_JOB_BY_ID_LOADING = "GET_JOB_BY_ID_LOADING"
export const GET_JOB_BY_ID_ERROR = "GET_JOB_BY_ID_ERROR"

export const GET_JOB_ROLES = "GET_JOB_ROLES "
export const GET_JOB_ROLES_LOADING = "GET_JOB_ROLES_LOADING"
export const GET_JOB_ROLES_ERROR = "GET_JOB_ROLES_ERROR"

export const GET_JOB_CATEGORIES = "GET_JOB_CATEGORIES"
export const GET_JOB_CATEGORIES_LOADING = "GET_JOB_CATEGORIES_LOADING"
export const GET_JOB_CATEGORIES_ERROR = "GET_JOB_CATEGORIES_ERROR"

export const GET_JOBS_FILTER_FOR_MAIN = "GET_JOBS_FILTER_FOR_MAIN"
export const GET_JOBS_FILTER_FOR_MAIN_LOADING = "GET_JOBS_FILTER_FOR_MAIN_LOADING"
export const GET_JOBS_FILTER_FOR_MAIN_ERROR = "GET_JOBS_FILTER_FOR_MAIN_ERROR"

export const GET_JOBS_FILTER_FOR_MAIN_TRUE = "GET_JOBS_FILTER_FOR_MAIN_TRUE"
export const GET_JOBS_FILTER_FOR_MAIN_FALSE = "GET_JOBS_FILTER_FOR_MAIN_FALSE"

export const GET_ACTIVE_JOBS_FILTER_FOR_MAIN = "GET_ACTIVE_JOBS_FILTER_FOR_MAIN"
export const GET_ACTIVE_JOBS_FILTER_FOR_MAIN_LOADING = "GET_ACTIVE_JOBS_FILTER_FOR_MAIN_LOADING"
export const GET_ACTIVE_JOBS_FILTER_FOR_MAIN_ERROR = "GET_ACTIVE_JOBS_FILTER_FOR_MAIN_ERROR"

export const GET_ACTIVE_JOBS_FILTER_FOR_MAIN_TRUE = "GET_ACTIVE_JOBS_FILTER_FOR_MAIN_TRUE"
export const GET_ACTIVE_JOBS_FILTER_FOR_MAIN_FALSE = "GET_ACTIVE_JOBS_FILTER_FOR_MAIN_FALSE"

export const JOB_UPDATE_MAIN = "JOB_UPDATE_MAIN"
export const JOB_UPDATE_LOADING = "JOB_UPDATE_LOADING"
export const JOB_UPDATE_ERROR = "JOB_UPDATE_ERROR"

export const ADD_JOB = "ADD_JOB"
export const ADD_JOB_LOADING = "ADD_JOB_LOADING"
export const ADD_JOB_ERROR = "ADD_JOB_ERROR"

export const DASHBOARD_JOBS = "DASHBOARD_JOBS"
export const DASHBOARD_JOBS_LOADING = "DASHBOARD_JOBS_LOADING"
export const DASHBOARD_JOBS_ERROR = "DASHBOARD_JOBS_ERROR"

export const JOB_UPDATE_ACTIVE_MAIN = "JOB_UPDATE_ACTIVE_MAIN"
export const JOB_UPDATE_ACTIVE_LOADING = "JOB_UPDATE_ACTIVE_LOADING"
export const JOB_UPDATE_ACTIVE_ERROR = "JOB_UPDATE_ACTIVE_ERROR"

//--- schemes --
export const GET_SCHEMES_CATEGORIE = " GET_SCHEMES_CATEGORIE"
export const GET_SCHEMES_CATEGORIES_LOADING = " GET_SCHEMES_CATEGORIES_LOADING"
export const GET_SCHEMES_CATEGORIES_ERROR = "GET_SCHEMES_CATEGORIES_ERROR"

export const GET_SCHEMES_CATEGORIES_BENIFITS = "GET_SCHEMES_CATEGORIES_BENIFITS"
export const GET_SCHEMES_CATEGORIES_BENIFITS_LOADING = "GET_SCHEMES_CATEGORIES_BENIFITS_LOADING"
export const GET_SCHEMES_CATEGORIES_BENIFITS_ERROR = "GET_SCHEMES_CATEGORIES_BENIFITS_ERROR"

export const GET_ALL_SCHEMES = " GET_ALL_SCHEMES "
export const GET_ALL_SCHEMES_LOADING = "GET_ALL_SCHEMES_LOADING "
export const GET_ALL_SCHEMES_ERROR = " GET_ALL_SCHEMES_ERROR"
export const GET_ALL_SCHEMES_TRUE = "GET_ALL_SCHEMES_TRUE"
export const GET_ALL_SCHEMES_FALSE = " GET_ALL_SCHEMES_FALSE"


export const GET_ONE_SCHEMES_BY_ID = "GET_ONE_SCHEMES_BY_ID "
export const GET_ONE_SCHEMES_BY_ID_LOADING = "GET_ONE_SCHEMES_BY_ID_LOADING"
export const GET_ONE_SCHEMES_BY_ID_ERROR = "GET_ONE_SCHEMES_BY_ID_ERROR"

export const ADD_ENROLLED_SCHEMES = "ADD_ENROLLED_SCHEMES"
export const ADD_ENROLLED_SCHEMES_LOADING = " ADD_ENROLLED_SCHEMES_LOADING"
export const ADD_ENROLLED_SCHEMES_ERROR = "ADD_ENROLLED_SCHEMES_ERROR"

export const ADD_SAVEED_SCHEMES = "ADD_SAVEED_SCHEMES"
export const ADD_SAVEED_SCHEMES_LOADING = "ADD_SAVEED_SCHEMES_LOADING"
export const ADD_SAVEED_SCHEMES_ERROR = "ADD_SAVEED_SCHEMES_ERROR"

export const DELETE_SAVEED_SCHEMES = "DELETE_SAVEED_SCHEMES"
export const DELETE_SAVEED_SCHEMES_LOADING = "DELETE_SAVEED_SCHEMES_LOADING"
export const DELETE_SAVEED_SCHEMES_ERROR = "DELETE_SAVEED_SCHEMES_ERROR"

export const DELETE_ENROLL_SCHEMES = "DELETE_ENROLL_SCHEMES"
export const DELETE_ENROLL_SCHEMES_LOADING = "DELETE_ENROLL_SCHEMES_LOADING"
export const DELETE_ENROLL_SCHEMES_ERROR = "DELETE_ENROLL_SCHEMES_ERROR"

export const GET_SCHEMES_RECOMMENDATION = "GET_SCHEMES_RECOMMENDATION"
export const GET_SCHEMES_RECOMMENDATION_LOADING = "GET_SCHEMES_RECOMMENDATION_LOADING"
export const GET_SCHEMES_RECOMMENDATION_ERROR = "GET_SCHEMES_RECOMMENDATION_ERROR"

//Frequently Asked Question

export const GET_QUERY = "GET_QUERY"
export const GET_QUERY_LOADING = "GET_QUERY_LOADING"
export const GET_QUERY_ERROR = "GET_QUERY_ERROR"

//--- schemes Rating --
export const ADD_SCHEMES_RATING = "ADD_SCHEMES_RATING"
export const ADD_SCHEMES_RATING_LOADING = "ADD_SCHEMES_RATING_LOADING"
export const ADD_SCHEMES_RATING_ERROR = "ADD_SCHEMES_RATING_ERROR"

export const GET_SCHEMES_RATING = "GET_SCHEMES_RATING"
export const GET_SCHEMES_RATING_LOADING = "GET_SCHEMES_RATING_LOADING"
export const GET_SCHEMES_RATING_ERROR = "GET_SCHEMES_RATING_ERROR"

export const SPECIFIC_SCHEMES_RATING = "SPECIFIC_SCHEMES_RATING"
export const SPECIFIC_SCHEMES_RATING_LOADING = "SPECIFIC_SCHEMES_RATING_LOADING"
export const SPECIFIC_SCHEMES_RATING_ERROR = "SPECIFIC_SCHEMES_RATING_ERROR"


//--- langugage --
export const ADD_LANGUAGE = "ADD_LANGUAGE_BEGINE"
export const ADD_LANGUAGE_LOADING = "ADD_LANGUAGE_LOADING"
export const ADD_LANGUAGE_ERROR = "ADD_LANGUAGE_ERROR"

export const GET_LANGUAGE = "GET_LANGUAGE"
export const GET_LANGUAGE_LOADING = "GET_LANGUAGE_LOADING"
export const GET_LANGUAGE_ERROR = "GET_LANGUAGE_ERROR"

export const GET_LANGUAGE_BY_NAME = "GET_LANGUAGE_BY_NAME_BEGINE"
export const GET_LANGUAGE_BY_NAME_LOADING = "GET_LANGUAGE_BY_NAME_LOADING"
export const GET_LANGUAGE_BY_NAME_ERROR = "GET_LANGUAGE_BY_NAME_ERROR"

// --- state --
export const GET_STATE = "GET_STATE"
export const GET_STATE_LODAING = "GET_STATE_LODAING"
export const GET_STATE_ERROR = "GET_STATE_ERROR"

//--- userPanel --
export const GET_JOB_BY_ID_USERPANEL = "GET_JOB_BY_ID_USERPANEL"
export const GET_JOB_BY_ID_USERPANEL_LODAING = "GET_JOB_BY_ID_USERPANEL_LODAING"
export const GET_JOB_BY_ID_USERPANEL_ERROR = "GET_JOB_BY_ID_USERPANEL_ERROR"

//--- banner --
export const GET_BANNERS = "GET_BANNERS"
export const GET_BANNERS_LODAING = "GET_BANNERS_LODAING"
export const GET_BANNERS_ERROR = "GET_BANNERS_ERROR"

//--- aticel --
export const GET_ARTICLE = "GET_ARTICLE"
export const GET_ARTICLE_LODAING = "GET_ARTICLE_LODAING"
export const GET_ARTICLE_ERROR = "GET_ARTICLE_ERROR"

export const ADD_SAVED_NEWS = "ADD_SAVED_NEWS"
export const ADD_SAVED_NEWS_LODAING = "ADD_SAVED_NEWS_LODAING"
export const ADD_SAVED_NEWS_ERROR = "ADD_SAVED_NEWS_ERROR"

export const DELETE_SAVED_NEWS = "DELETE_SAVED_NEWS"
export const DELETE_SAVED_NEWS_LODAING = "DELETE_SAVED_NEWS_LODAING"
export const DELETE_SAVED_NEWS_ERROR = "DELETE_SAVED_NEWS_ERROR"



// --- testimonial
export const GET_ALL_TESTIMONIAL = "GET_ALL_TESTIMONIAL"
export const GET_ALL_TESTIMONIAL_LODAING = "GET_ALL_TESTIMONIAL_LODAING"
export const GET_ALL_TESTIMONIAL_ERROR = "GET_ALL_TESTIMONIAL_ERROR"

export const GET_TESTIMONIAL = "GET_TESTIMONIAL"
export const GET_TESTIMONIAL_LODAING = "GET_TESTIMONIAL_LODAING"
export const GET_TESTIMONIAL_ERROR = "GET_TESTIMONIAL_ERROR"

// --- contactUs ---

export const GET_CONTACTUS = "GET_CONTACTUS"
export const GET_CONTACTUS_LODAING = "GET_CONTACTUS_LODAING"
export const GET_CONTACTUS_ERROR = "GET_CONTACTUS_ERROR"

//--- privacy policy ---

export const GET_GENERALDATA = "GET_GENERALDATA"
export const GET_GENERALDATA_LOADING = "GET_GENERALDATA_LOADING"
export const GET_GENERALDATA_ERROR = "GET_GENERALDATA_ERROR"

//--- Job Application --
export const ADD_JOB_APPLICATION = "ADD_JOB_APPLICATION"
export const ADD_JOB_APPLICATION_LODAING = "ADD_JOB_APPLICATION_LODAING"
export const ADD_JOB_APPLICATION_ERROR = "ADD_JOB_APPLICATION_ERROR"

export const JOB_APPLICATION_COUNT = "JOB_APPLICATION_COUNT"
export const JOB_APPLICATION_COUNT_LOADING = "JOB_APPLICATION_COUNT_LOADING"
export const JOB_APPLICATION_COUNT_ERROR = "JOB_APPLICATION_COUNT_ERROR"

export const GET_ALL_JOB_APPLICATION = "GET_ALL_JOB_APPLICATION"
export const GET_ALL_JOB_APPLICATION_LOADING = "GET_ALL_JOB_APPLICATION_LOADING"
export const GET_ALL_JOB_APPLICATION_ERROR = "GET_ALL_JOB_APPLICATION_ERROR"

export const UPDATE_JOB_APPLICATION = "UPDATE_JOB_APPLICATION"
export const UPDATE_JOB_APPLICATION_LOADING = "UPDATE_JOB_APPLICATION_LOADING"
export const UPDATE_JOB_APPLICATION_ERROR = "UPDATE_JOB_APPLICATION_ERROR"

export const GET_JOB_APPLICATION = "GET_JOB_APPLICATION"
export const GET_JOB_APPLICATION_LOADING = "GET_JOB_APPLICATION_LOADING"
export const GET_JOB_APPLICATION_ERROR = "GET_JOB_APPLICATION_ERROR"


//--- Saved Job --
export const ADD_SAVED_JOB = "ADD_SAVED_JOB"
export const ADD_SAVED_JOB_LODAING = "ADD_SAVED_JOB_LODAING"
export const ADD_SAVED_JOB_ERROR = "ADD_SAVED_JOB_ERROR"

export const DELETE_SAVED_JOB = "DELETE_SAVED_JOB"
export const DELETE_SAVED_JOB_LODAING = "DELETE_SAVED_JOB_LODAING"
export const DELETE_SAVED_JOB_ERROR = "DELETE_SAVED_JOB_ERROR"

//--- district --
export const GET_DISTRICT = "GET_DISTRICT"
export const GET_DISTRICT_LOADING = "GET_DISTRICT_LOADING"
export const GET_DISTRICT_ERROR = "GET_DISTRICT_ERROR"

// --- Carousel -- 
export const GET_CAROUSEL = "GET_CAROUSEL"
export const GET_CAROUSEL_LOADING = "GET_CAROUSEL_LOADING"
export const GET_CAROUSEL_ERROR = "GET_CAROUSEL_ERROR"

// --- user -- 
export const GET_ALL_USER = "GET_ALL_USER"
export const GET_ALL_USERL_LOADING = "GET_ALL_USERL_LOADING"
export const GET_ALL_USER_ERROR = "GET_ALL_USER_ERROR"

export const USER_PROFILE_IMAGE = "USER_PROFILE_IMAGE"
export const EMPLOYER_PROFILE_IMAGE = "EMPLOYER_PROFILE_IMAGE"
export const PARTNER_PROFILE_IMAGE = "PARTNER_PROFILE_IMAGE"
export const PROFILE_IMAGE_LODING = "PROFILE_IMAGE_LODING"
export const PROFILE_IMAGE_ERROR = "PROFILE_IMAGE_ERROR"


// ---FAQs --
export const GET_FAQ = 'GET_FAQ'
export const GET_FAQ_LOADING = 'GET_FAQ_LOADING'
export const GET_FAQ_ERROR = 'GET_FAQ_ERROR'

export const ADD_FAQ = 'ADD_FAQ'
export const ADD_FAQ_LOADING = 'ADD_FAQ_LOADING '
export const ADD_FAQ_ERROR = 'ADD_FAQ_ERROR'

export const EDIT_FAQ = 'EDIT_FAQ'
export const EDIT_FAQ_LOADING = 'EDIT_FAQ_LOADING '
export const EDIT_FAQ_ERROR = 'EDIT_FAQ_ERROR'

export const GET_ALL_FAQ = 'GET_ALL_FAQ'
export const GET_ALL_FAQ_LOADING = 'GET_ALL_FAQ_LOADING '
export const GET_ALL_FAQ_ERROR = 'GET_ALL_FAQ_ERROR'

export const DELETE_FAQ = 'DELETE_FAQ'
export const DELETE_FAQ_LOADING = 'DELETE_FAQ_LOADING '
export const DELETE_FAQ_ERROR = 'DELETE_FAQ_ERROR'

//  --- InspirationalStories --
export const INSPIRATIONAL_STORIES_JOB = 'INSPIRATIONAL_STORIES_JOB'
export const INSPIRATIONAL_STORIES_SCHEME = 'INSPIRATIONAL_STORIES_SCHEME'
export const INSPIRATIONAL_STORIES_LOADING = 'INSPIRATIONAL_STORIES_LOADING '
export const INSPIRATIONAL_STORIES_ERROR = 'INSPIRATIONAL_STORIES_ERROR'


// --- sendMailToHR --
export const SEND_MAIL_TO_HR = 'SEND_MAIL_TO_HR'
export const SEND_MAIL_TO_HR_LOADING = 'SEND_MAIL_TO_HR_LOADING '
export const SEND_MAIL_TO_HR_ERROR = 'SEND_MAIL_TO_HR_ERROR'


// --- Get start --
export const ADD_GET_STARTED = "ADD_GET_STARTED"
export const ADD_GET_STARTED_LODAING = "ADD_GET_STARTED_LODAING"
export const ADD_GET_STARTED_ERROR = "ADD_GET_STARTED_ERROR"
