import { TextField } from "@mui/material";
import { log } from "console";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import STORAGEKEY from "../../config/APP/app.config";
import { ApiGet, ApiGetNoAuth, ApiPost, ApiPostNoAuth } from "../../helper/API/ApiData";
import AuthStorage from "../../helper/AuthStorage";
import { IsLogin } from "../../redux/actions/isLoginAction";
import { loginAction } from "../../redux/actions/loginAction";
import { userAuthSignup } from "../../redux/actions/signupAction";
import { USER_AUTH_SIGNUP } from "../../redux/type";

import CreateAccountmodal from "./CreateAccountmodal";
import ForgotPasswordModal from "./ForgotPasswordModal";
import ForgotPasswordSuccesModel from "./ForgotPasswordSuccesModel";
import { useTranslation } from "react-i18next";


interface loginData {
    email?: string;
    password?: string;
}
interface register {
    userName: string,
    phoneNo: string,
    email: any,
    password: any,
    onHide?: ((e: any) => void) | any;
    otp: any,
    verifayOtp: any,
}
const RegisterYourAccount = ({ show, onHide, userType }: any) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const userAuthSingupData = useSelector((state: any) => state.signupData.userAuthSingup)
    const userAuthSingupError = useSelector((state: any) => state.signupData.userAuthSingupError)
    const teapmError = useSelector((state: any) => state.signupData.signUpData)

    const [mobileNumber, setMobileNumber] = useState("");
    const [verifactionTab, setVerifactionTab] = useState(true);
    const [type, setType] = useState<string>('password')
    const [selectLoginType, setSelectLoginType] = useState("USER");
    const [termsConditions, setTermsConditions] = useState<boolean>(false);
    const [loginType, setLoginType] = useState(false);
    const [otp, setOtp] = useState('');
    const [error, setError] = useState<loginData>({
        email: '',
        password: '',
    });
    const [register, setRegister] = useState<register>({
        userName: '',
        phoneNo: '',
        email: '',
        password: '',
        otp: "",
        verifayOtp: ""
    });
    const [formErrors, setFormErrors] = useState({
        userName: '',
        phoneNo: '',
        email: '',
        password: '',
        termsConditionsError: '',
        verifayOtp: ''
    })

    useEffect(() => {
        let OTPCode: any = Math.floor(100000 + Math.random() * 900000).toString();
        setRegister({ ...register, otp: OTPCode })
    }, [])
    const handalonChnage = (e: any, name: any) => {
        const regexphone = /^[0-9\b]+$/;
        if (name === "userName" || name === "email" || name === "password") {
            setRegister({ ...register, [name]: e.target.value })
        } else if (name === "termsConditions") {
            setTermsConditions(e.target.checked)
        }
        else if (name === "verifayOtp") {
            if (e.target.value === '' || regexphone.test(e.target.value)) {
                setRegister({ ...register, verifayOtp: e.target.value })
            }
        }
        setFormErrors({ ...formErrors, [name]: "" });
    }
    const handleOnChange = (e: any, name: string) => {
        const regexphone = /^[0-9\b]+$/;
        if (name === "phoneNo") {
            if (e.target.value === '' || regexphone.test(e.target.value)) {
                setRegister({ ...register, phoneNo: e.target.value })
                setFormErrors({ ...formErrors, phoneNo: '' })
            }
        }

    }
    useEffect(() => {
        if (userType) {
            setSelectLoginType(userType.toUpperCase())
        }
    }, [userType])

    const validation = () => {
        const regexForEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        let flag = false
        const error = {
            userName: '',
            phoneNo: '',
            email: '',
            password: '',
            termsConditionsError: '',
            verifayOtp: '',
        };
        if (!register.userName) {
            error.userName = "Please enter name"
            flag = true
        };
        if (!register.phoneNo) {
            error.phoneNo = "Please enter phone number"
            flag = true
        };

        if (register.phoneNo && register.phoneNo.length < 10) {
            error.phoneNo = "Please enter valid phone number"
            flag = true
        }

        if (!register.email) {
            error.email = "Please enter email"
            flag = true
        };

        if (register.email && !register.email.match(regexForEmail)) {
            error.email = "Please enter valid email"
            flag = true
        }
        if (!register.password) {
            error.password = "Please enter password"
            flag = true
        };
        if (!termsConditions) {
            error.termsConditionsError = "You must accept the terms and conditions"
            flag = true
        }
        if (!verifactionTab) {
            if (!register.verifayOtp) {
                error.verifayOtp = "plase enter verification code"
                flag = true
            }
            if (register.verifayOtp && register.otp !== register.verifayOtp) {
                error.verifayOtp = "Invalid verification code"
                flag = true
            }
        }
        setFormErrors(error)
        return flag
    }
    const createAccount = () => {
        if (validation()) {
            return
        }
        let body = {
            name: register.userName,
            email: register.email,
            phone: register.phoneNo,
            otp: CryptoJS.AES.encrypt(JSON.stringify(register.otp), 'XkhZG4fW2t2WSWAYAMVERIFICATION').toString()
        }
        ApiPostNoAuth('user/auth/sendOtp', body).then((res: any) => {
            if (res.status === 200) {
                setVerifactionTab(!verifactionTab)
            }
        }).catch((error) => {
            toast.error(error)
            return error
        })
    }
    const resend = () => {
        let body = {
            name: register.userName,
            email: register.email,
            phone: register.phoneNo,
            otp: CryptoJS.AES.encrypt(JSON.stringify(register.otp), 'XkhZG4fW2t2WSWAYAMVERIFICATION').toString()
        }
        ApiPostNoAuth('user/auth/sendOtp', body).then((res: any) => {
            if (res.status === 200) {
                toast.success("OTP sent successfully")
            }
        }).catch((error) => {
            return error
        })
    }
    const verifayAccount = () => {
        if (validation()) {
            return
        }
        if (register.otp === register.verifayOtp) {
            let body = {
                name: register.userName,
                email: register.email,
                password: register.password,
                phone: register.phoneNo,
                userType: selectLoginType,
                deviceId: '',
                OS: '',
                version: '',
                deviceMake: '',
            }
            dispatch(userAuthSignup(body))
        }
    }
    useEffect(() => {
        if (userAuthSingupData && userAuthSingupData.message === "User already exists") {
            dispatch({
                type: USER_AUTH_SIGNUP,
                payload: null,
            })
        } else {
            if (userAuthSingupData?.status === 200) {
                onHide('');
                let data = {
                    email: register.email,
                    password: register.password,
                    userType: selectLoginType.toUpperCase(),
                    loginType: "EMAIL",
                }
                if (!(data.userType === "EMPLOYER" || data.userType === "PARTNER")) {
                    dispatch(loginAction(data, selectLoginType, true));
                }
                dispatch({
                    type: USER_AUTH_SIGNUP,
                    payload: null,
                })
                if (selectLoginType === "USER") {
                    navigate("/get_started")
                }
            }
        }
    }, [userAuthSingupData])

    const onChange = (e: any) => {
        const re = /^[0-9\b]+$/;
        if (!e.target.value || e.target.value === "" || re.test(e.target.value)) {
            setMobileNumber(e.target.value);
        }
    }

    const handleChange = (e: any) => {
        if (e.target.id === 'email') {
            setLoginType(true)
        } else {
            setLoginType(false)
        }
    }

    return (
        <>
            <Modal
                show={show}
                onHide={() => onHide('')}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter" setLoginType
                centered
                className="loginmodal"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    {verifactionTab ?
                        <div className="loginbody row">
                            <div className="closeButton">
                                <img src="./assets/img/wrong.png" alt="" onClick={() => { onHide('') }} />
                            </div>

                            <div className="col-lg-6 d-lg-block d-none">
                                <img src="./assets/img/RegisterYourAccount.png" alt="" width="100%" height="100%" />
                            </div>

                            <div className="col-lg-6 login-form ">
                                <h1>{t("registermodal.title")}</h1>
                                <h2>{t("registermodal.sub_title")}</h2>

                                <div className="d-flex flex-wrap confirmation-buttons">
                                    <button onClick={() => setSelectLoginType("USER")} className={`mt-3 ${selectLoginType === "USER" ? "active" : "disable"}`}> <img src={`${selectLoginType === "USER" ? './assets/img/noun-job-active.png' : './assets/img/noun-job-disable.png'}`} alt="" width="24px" height="24px" />{t("registermodal.cand_btn")}</button>
                                    <button onClick={() => setSelectLoginType("EMPLOYER")} className={`mt-3 ${selectLoginType === "EMPLOYER" ? "active" : "disable"}`}> <img src={`${selectLoginType === "EMPLOYER" ? './assets/img/noun-events-active.png' : './assets/img/noun-events-disable.png'}`} alt="" width="24px" height="24px" /> {t("registermodal.emp_btn")}</button>
                                    <button onClick={() => setSelectLoginType("PARTNER")} className={`mt-3 ${selectLoginType === "PARTNER" ? "active" : "disable"}`}> <img src={`${selectLoginType === "PARTNER" ? './assets/img/noun-vacancy-active.png' : './assets/img/noun-vacancy-disable.png'}`} alt="" width="24px" height="24px" /> {t("registermodal.partner")}</button>
                                </div>

                                <div className="mt-3">
                                    <TextField autoComplete='off' id="outlined-danger" type="text" label={t("registermodal.name_lbl")} name="userName" value={register.userName} variant="outlined" onChange={(e) => handalonChnage(e, "userName")} />
                                    {formErrors.userName && <span style={{ color: 'red' }}>{formErrors.userName}</span>}
                                </div>
                                <div className="mt-3">
                                    <TextField
                                        autoComplete='off'
                                        className="mi-input"
                                        id="outlined-danger"
                                        type="text"
                                        name="phoneNo"
                                        value={register.phoneNo}
                                        label={t("registermodal.mobile_lbl")} variant="outlined"
                                        onChange={(e) => handleOnChange(e, "phoneNo")}
                                        inputProps={{
                                            maxLength: 10,
                                        }}
                                    />
                                    {formErrors.phoneNo && <span style={{ color: 'red' }}>{formErrors.phoneNo}</span>}
                                </div>
                                <div className="mt-3">
                                    <TextField autoComplete='off' id="outlined-danger" type="email" label={t("registermodal.email_lbl")} name="email" value={register.email} variant="outlined" onChange={(e) => handalonChnage(e, "email")} />
                                    {formErrors.email && <span style={{ color: 'red' }}>{formErrors.email}</span>}
                                </div>
                                <div className="mt-3  position-relative">
                                    <TextField autoComplete='off' id="outlined-danger" type={type} name="password" value={register.password} label={t("registermodal.pass_lbl")} variant="outlined" onChange={(e) => handalonChnage(e, "password")} />
                                    {formErrors.password && <span style={{ color: 'red' }}>{formErrors.password}</span>}
                                    <img className="show-password" src={type === "password" ? './assets/img/view.png' : './assets/img/hidden.png'} onClick={() => type === "password" ? setType('text') : setType('password')} alt="" />
                                </div>
                                <div className="via-checkbox mt-4">
                                    <div className="text-center" style={{ display: 'flex', alignItems: 'center' }} >
                                        <input type="checkbox" name="selectgender" id="female" className="form-check-input" onChange={(e) => handalonChnage(e, "termsConditions")} />
                                        <label htmlFor="female" className="upload-image-resume-lable" style={{ fontWeight: '300' }}>I agree to <span style={{ textDecoration: 'none', color: '#C90F22', cursor: "pointer", fontWeight: "bold" }} onClick={() => { navigate('/privacypolicy'), onHide('') }}>Conditions  of Use</span> and <span style={{ textDecoration: 'none', color: '#C90F22', cursor: "pointer", fontWeight: "bold" }} onClick={() => { navigate('/privacypolicy'), onHide('') }}>Privacy Policy</span>.</label>
                                    </div>
                                    {formErrors.termsConditionsError && <span style={{ color: 'red' }}>{formErrors.termsConditionsError}</span>}

                                </div>
                                <div className="text-center login-button mt-2">
                                    <button onClick={() => createAccount()}>{t("registermodal.create_btn")}</button>
                                </div>
                                <div className="text-center mt-3">
                                    <p className="m-0" style={{ color: '#747477', fontSize: "16px", lineHeight: '15px' }}>{t("registermodal.have_account")} <span style={{ textDecoration: 'none', color: '#C90F22', cursor: "pointer" }} onClick={() => onHide('login')}>Log in</span></p>
                                </div>
                            </div>
                        </div> :
                        <div className="loginbody row">
                            <div className="closeButton">
                                <img src="./assets/img/wrong.png" alt="" onClick={() => { onHide('') }} />
                            </div>

                            <div className="col-lg-6 d-lg-block d-none">
                                <img src="./assets/img/RegisterYourAccount.png" alt="" width="100%" height="100%" />
                            </div>

                            <div className="col-lg-6 login-form ">
                                <h1>{t("registermodal.verify_title")}</h1>
                                <p>We have sent you verification code by SMS and email. Kindly check and verify your registration.</p>
                                <div className="mt-3">
                                    <TextField autoComplete='off' id="outlined-danger" type="text" label={t("registermodal.verify_lable")} name="verifayOtp" value={register.verifayOtp} variant="outlined" inputProps={{ maxLength: 6 }} onChange={(e) => handalonChnage(e, "verifayOtp")} />
                                    {formErrors.verifayOtp && <span style={{ color: 'red' }}>{formErrors.verifayOtp}</span>}
                                    <p className="text-end mt-2" style={{ cursor: "pointer", width: "fit-content", marginLeft: "auto", color: "#C90F22" }} onClick={() => resend()}>{t("registermodal.resend")}</p>
                                </div>
                                <div className="text-center login-button mt-2">
                                    <button onClick={() => verifayAccount()}>{t("registermodal.verify_button")}</button>
                                </div>
                            </div>
                        </div>

                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default RegisterYourAccount