import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getDistrict } from '../redux/actions/districtAction'
import { addJob, getJobCategories, getJobRoles, getJobsFilterForMain, updateJob } from '../redux/actions/jobAction'
import { getAllState } from '../redux/actions/stateAction'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import STORAGEKEY from '../config/APP/app.config'
import { useLocation, useNavigate } from 'react-router-dom'
import { getJobByIdUserPanel } from '../redux/actions/userPanelAction'
import AuthStorage from '../helper/AuthStorage'
import { ADD_JOB, JOB_UPDATE_MAIN } from '../redux/type'
import { toast } from 'react-toastify'
import { ApiDelete } from '../helper/API/ApiData'
import { v1 as uuidv1 } from 'uuid';
import swal from 'sweetalert'

const PostJob = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const jobId = queryParams.get("id")
    const key = queryParams.get("key")
    const lagId = queryParams.get("lanId")
    const addIn = queryParams.get("addIn")

    const UserData: any = AuthStorage.getStorageData(STORAGEKEY.userId)
    const [perPage, setPerPage] = useState(8)
    const [pageNumber, setPageNumber] = useState(1)
    const [editData, setEditData] = useState<any>({})

    const [postJob, setPostJob] = useState<any>({
        name: UserData,
        jobType: "",
        jobRole: "",
        description: "",
        priority: "",
        vacancies: "",
        reqExperience: "",
        salary: "",
        benifits: "",
        requirements: "",
        type: "",
        extraType: "",
        isActive: false,
        shifts: "",
        application_form: "",
        recommended_and_forwarded: "",
        application_process: "",
        medical_superintendent: "",
        hospital_expenses_estimation_certificate: "",
        workingHours: 1,
        jobDetails: "",
        documentation: "",
        thumbnail: "",
        bannerImage: "",
        pincode: "",
        state: "",
        district: "",
        town: "",
        phone: "",
        email: "",
        startDate: "",
        endDate: "",
        key: "",
    })

    const [formErrors, setFormErrors] = useState({
        jobType: "",
        jobRole: "",
        description: "",
        vacancies: "",
        reqExperience: "",
        salary: "",
        benifits: "",
        requirements: "",
        type: "",
        extraType: "",
        isActive: "",
        shifts: "",
        application_form: "",
        recommended_and_forwarded: "",
        application_process: "",
        medical_superintendent: "",
        hospital_expenses_estimation_certificate: "",
        workingHours: "",
        jobDetails: "",
        documentation: "",
        thumbnail: "",
        bannerImage: "",
        pincode: "",
        state: "",
        district: "",
        town: "",
        phone: "",
        email: "",
        startDate: "",
        endDate: "",
    })
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const getStateData = useSelector((state: any) => state.stateData.stateData)
    const getJobRolesData = useSelector((state: any) => state.jobData.getJobRoles)
    const getDistrictData = useSelector((state: any) => state.districtData.districtData)
    const getOneDataById = useSelector((state: any) => state.userPanelData.getJobById)
    const updateJobData = useSelector((state: any) => state.jobData.updateJob)
    const addJobData = useSelector((state: any) => state.jobData.addJob)
    const jobCategories = useSelector((state: any) => state.jobData.getJobCategories)

    useEffect(() => {
        if (jobId) {
            dispatch(getJobByIdUserPanel(jobId))
        }
    }, [jobId])

    useEffect(() => {
        if (lagId) {
            dispatch(getAllState(lagId))
            dispatch(getJobRoles(lagId))
            dispatch(getJobCategories(lagId))
        } else {
            dispatch(getAllState(undefined))
            dispatch(getJobRoles(undefined))
            dispatch(getJobCategories(undefined))
        }
    }, [key, lagId])

    useEffect(() => {
        if (postJob.state) {
            dispatch(getDistrict(postJob.state, lagId))
        }
    }, [postJob.state])

    useEffect(() => {
        if (updateJobData.status === 200) {
            toast.success("Job post updated")
            clear()
            dispatch({
                type: JOB_UPDATE_MAIN,
                payload: ""
            })
            navigate(-1)
        }
    }, [updateJobData])

    useEffect(() => {
        if (addJobData.status === 200) {
            toast.success("Job post added")
            clear()
            dispatch({
                type: ADD_JOB,
                payload: ""
            })
            navigate(-1)
        }
    }, [addJobData])

    useEffect(() => {
        dispatch(getJobsFilterForMain(perPage, pageNumber, "", "", "", "", "", false, ""))
    }, [perPage, pageNumber])

    useEffect(() => {
        if (getOneDataById && getOneDataById.data && getOneDataById.data.data)
            setEditData(getOneDataById.data.data)
    }, [getOneDataById])

    useEffect(() => {
        if (jobId && editData?.id) {
            setPostJob({
                ...postJob,
                name: UserData,
                jobType: editData?.jobType?.id || '',
                jobRole: editData?.jobRole?.id || '',
                description: editData?.description || '',
                vacancies: editData?.vacancies || '',
                priority: editData?.priority || '',
                reqExperience: editData?.reqExperience || '',
                salary: editData?.salary || '',
                benifits: editData?.benifits || '',
                requirements: editData?.requirements || '',
                type: editData?.type || '',
                extraType: editData?.extraType || '',
                isActive: editData?.isActive?.toString() || '',
                shifts: editData?.shifts || '',
                application_form: editData?.application_form || '',
                recommended_and_forwarded: editData?.recommended_and_forwarded || '',
                application_process: editData?.application_process || '',
                medical_superintendent: editData?.medical_superintendent || '',
                hospital_expenses_estimation_certificate: editData?.hospital_expenses_estimation_certificate || '',
                jobDetails: editData?.jobDetails || '',
                documentation: editData?.documentation || '',
                workingHours: editData?.workingHours || '',
                thumbnail: editData?.thumbnail || '',
                bannerImage: editData?.bannerImg || '',
                pincode: editData?.pincode || '',
                state: editData?.state?.id || '',
                district: editData?.district?.id ? editData?.district?.id : "",
                town: editData?.town || '',
                phone: editData?.phone || '',
                email: editData?.email || '',
                startDate: moment(editData?.startData).format('yyyy-MM-DD') || '',
                endDate: moment(editData?.endDate).format('yyyy-MM-DD') || '',
            })
        }
    }, [editData, jobId]);

    const handleChange = (e: any, name: string) => {
        setFormErrors({ ...formErrors, [name]: '' })
        let re = /^[0-9\b]+$/;
        if (name === "type" || name === "requirements" || name === "extraType" || name === "benifits" || name === "name" || name === "jobRole" || name === "jobType" || name === "consultant" || name === "document" || name === "documentURL" || name === "district" || name === "state" || name === "other" || name === "recruiters" || name === "email" || name === "website" || name === "logoURL" || name === "town" || name === "description" || name === "isActive" || name === "shifts" || name === "application_form" || name === "recommended_and_forwarded" || name === "application_process" || name === "medical_superintendent" || name === "hospital_expenses_estimation_certificate" || name === "documentation" || name === 'jobDetails') {
            setPostJob({ ...postJob, [name]: e.target.value })
        } else if (name === "pincode" || name === "phone" || name === "vacancies" || name === "reqExperience") {
            if (e.target.value === "" || re.test(e.target.value)) {
                setPostJob({ ...postJob, [name]: e.target.value })
            }
        } else if (name === "startDate" || name === "endDate") {
            setPostJob({ ...postJob, [name]: e.target.value })
        } else if (name === "priority" || name === "salary" || name === 'workingHours') {
            const value = e.target.value.replace(/\+|-/ig, '')
            setPostJob({ ...postJob, [name]: value })
        }
    }
    const validation = () => {
        let phoneNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/
        let pincodeRegex = /^[1-9][0-9]{5}$/
        let flag = false
        const error = {
            jobType: "",
            jobRole: "",
            description: "",
            vacancies: "",
            reqExperience: "",
            salary: "",
            benifits: "",
            requirements: "",
            type: "",
            extraType: "",
            isActive: "",
            shifts: "",
            application_form: "",
            recommended_and_forwarded: "",
            application_process: "",
            medical_superintendent: "",
            hospital_expenses_estimation_certificate: "",
            workingHours: "",
            jobDetails: "",
            documentation: "",
            thumbnail: "",
            bannerImage: "",
            pincode: "",
            state: "",
            district: "",
            town: "",
            phone: "",
            email: "",
            startDate: "",
            endDate: "",
        };
        if (!postJob.jobType) {
            error.jobType = "Please select Job type"
            flag = true
        }
        if (!postJob.jobRole) {
            error.jobRole = "Please select Job role"
            flag = true
        }
        if (!postJob.description) {
            error.description = "Please enter description"
            flag = true
        }
        if (!postJob.vacancies) {
            error.vacancies = "Please enter vacancies"
            flag = true
        }
        if (!postJob.reqExperience) {
            error.reqExperience = "Please enter required experience"
            flag = true
        }
        if (!postJob.salary) {
            error.salary = "Please enter salary"
            flag = true
        }
        if (!postJob.benifits) {
            error.benifits = "Please enter benefits"
            flag = true
        }
        if (!postJob.type) {
            error.type = "Please select type"
            flag = true
        }
        if (!postJob.extraType) {
            error.extraType = "Please select extra type"
            flag = true
        }
        // if (postJob.isActive === "") {
        //     error.isActive = "Please select isactive"
        //     flag = true
        // }
        if (!postJob.shifts) {
            error.shifts = "Please select shifts"
            flag = true
        }
        if (!postJob.application_form) {
            error.application_form = "Enter application form"
            flag = true
        }
        if (!postJob.application_process) {
            error.application_process = "Enter application process"
            flag = true
        }
        if (!postJob.thumbnail) {
            error.thumbnail = "Select thumbnail"
            flag = true
        }
        if (!postJob.bannerImage) {
            error.bannerImage = "Select bannerImage"
            flag = true
        }
        if (!postJob.workingHours) {
            error.workingHours = "Enter workingHours"
            flag = true
        }
        if (!postJob.documentation) {
            error.documentation = "Enter documentation"
            flag = true
        }
        if (!postJob.pincode) {
            error.pincode = "Please enter pincode"
            flag = true
        } else if (postJob.pincode && !pincodeRegex.test(postJob.pincode)) {
            error.pincode = "Please enter valiad pincode"
            flag = true
        }
        if (!postJob.state) {
            error.state = "Please select state"
            flag = true
        }
        if (!postJob.district) {
            error.district = "Please select city"
            flag = true
        }
        if (!postJob.town) {
            error.town = "Please enter town"
            flag = true
        }
        if (!postJob.phone) {
            error.phone = "Please enter mobile no"
            flag = true
        } else if (postJob.phone && !phoneNumberRegex.test(postJob.phone)) {
            error.phone = "Please enter valiad mobile no"
            flag = true
        }
        if (!postJob.email) {
            error.email = "Please enter email"
            flag = true
        } else if (postJob.email && !regex.test(postJob.email)) {
            error.email = "Please enter valid email"
            flag = true
        }
        if (!postJob.startDate) {
            error.startDate = "Please select start date"
            flag = true
        }
        if (!postJob.endDate) {
            error.endDate = "Please select end date"
            flag = true
        }
        setFormErrors(error);
        return flag
    }

    const fileUpload = (e: any, name: any) => {
        let firsttemp = e.target.files[0]?.name?.split('.');
        if (firsttemp) {
            let fileexten = ['jpeg', 'jpg', 'png']
            if (fileexten.includes(firsttemp[firsttemp.length - 1])) {
                setPostJob({ ...postJob, [name]: e.target.files[0] })
                setFormErrors({ ...formErrors, [name]: "" });
            }
            else {
                setFormErrors({ ...formErrors, [name]: 'Please select valid document file' })
            }
        }
        else {
            setFormErrors({ ...formErrors, [name]: 'Please select document file' })
        }
    }

    const clear = () => {
        setPostJob({
            ...postJob,
            name: "",
            jobType: "",
            jobRole: "",
            description: "",
            vacancies: "",
            reqExperience: "",
            salary: "",
            benifits: "",
            requirements: "",
            type: "",
            extraType: "",
            shifts: "",
            application_form: "",
            recommended_and_forwarded: "",
            application_process: "",
            medical_superintendent: "",
            hospital_expenses_estimation_certificate: "",
            priority: "",
            jobDetails: "",
            documentation: "",
            workingHours: "",
            isActive: "",
            thumbnail: "",
            bannerImage: "",
            pincode: "",
            state: "",
            district: "",
            town: "",
            phone: "",
            email: "",
            startDate: "",
            endDate: "",
        })
    }
    const save = () => {
        if (validation()) {
            return
        }
        let formData = new FormData();
        formData.append('name', postJob.name,)
        formData.append('state', postJob.state)
        formData.append('district', postJob.district,)
        formData.append('town', postJob.town,)
        formData.append('pincode', postJob.pincode,)
        formData.append('description', postJob.description,)
        formData.append('vacancies', postJob.vacancies)
        formData.append('reqExperience', postJob.reqExperience,)
        formData.append('salary', postJob.salary,)
        formData.append('benifits', postJob.benifits.toString('html'),)
        formData.append('requirements', postJob.requirements,)
        formData.append('type', postJob.type,)
        formData.append('extraType', postJob.extraType,)
        formData.append('shifts', postJob.shifts,)
        formData.append('email', postJob.email,)
        formData.append('phone', postJob.phone,)
        formData.append('startDate', moment.utc(postJob.startDate).format(),)
        formData.append('endDate', moment.utc(postJob.endDate).format(),)
        formData.append('jobRole', postJob.jobRole,)
        formData.append('jobType', postJob.jobType,)
        formData.append('application_form', postJob.application_form,)
        formData.append('application_process', postJob.application_process,)
        formData.append('priority', postJob.priority ? postJob.priority : 1,)
        formData.append('thumbnail', postJob.thumbnail,)
        formData.append('bannerImg', postJob.bannerImage)
        formData.append('workingHours', postJob.workingHours,)
        formData.append('documentation', postJob.documentation.toString('html'),)
        formData.append('jobDetails', postJob.jobDetails,)
        formData.append('isActive', postJob.isActive)
        if (jobId) {
            dispatch(updateJob(jobId, formData))
        } else {
            if (key) {
                formData.append('key', key);
                dispatch(addJob(formData, lagId));
            } else {
                formData.append('key', uuidv1());
                dispatch(addJob(formData, undefined));
            }
        }
    }
    const handleDelete = () => {
        swal({
            title: "Are you sure?",
            text: "you want to delete it?",
            icon: "error",
            buttons: ["cancel", "ok"],
            dangerMode: false,
        }).then((result) => {
            if (result) {
                ApiDelete(`job/deleteSingalJob?id=${jobId}`, {})
                    .then((res: any) => {
                        if (res.status === 200) {
                            toast.success("job deleted")
                            navigate(-1)
                        }
                    }).catch((error) => { return error })
            }
        })
    }



    return (
        <div className='my_profile_main'>
            <Container fluid>
                <h1 className='heading-txt m-0'>{jobId ? `Edit job ${addIn ? 'in' + " " + addIn : ""}` : `Post a job ${addIn ? 'in' + " " + addIn : ""}`}</h1>
                <div className='addtrainingpartner_main'>
                    <h1 className='heading-txt m-0'>{t("Employee.JobPost.generalinfo.title")}</h1>
                    <div className='General_Info'>
                        <Row>
                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t("Employee.JobPost.generalinfo.jobType")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={postJob?.jobType}
                                        name="jobtype"
                                        label={t("Employee.JobPost.generalinfo.jobType")}
                                        onChange={(e) => handleChange(e, "jobType")}
                                    >
                                        {
                                            jobCategories && jobCategories.data && getJobRolesData.data?.length > 0 ?
                                                jobCategories.data.map((ele: any, i: number) => <MenuItem key={i} value={ele.id}>{ele.name}</MenuItem>) : ""
                                        }

                                    </Select>
                                </FormControl>
                                {formErrors?.jobType && <span style={{ color: "red" }}>{formErrors.jobType}</span>}
                            </Col>

                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t("Employee.JobPost.generalinfo.jobRole")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={postJob?.jobRole}
                                        name="jobRole"
                                        label={t("Employee.JobPost.generalinfo.jobRole")}
                                        onChange={(e) => handleChange(e, "jobRole")}
                                    >
                                        {
                                            getJobRolesData && getJobRolesData.data && getJobRolesData.data.length > 0 ?
                                                getJobRolesData.data.map((ele: any, i: number) => <MenuItem key={i} value={ele?.id}>{ele?.name}</MenuItem>) : ""
                                        }

                                    </Select>
                                </FormControl>
                                {formErrors?.jobRole && <span style={{ color: "red" }}>{formErrors.jobRole}</span>}
                            </Col>
                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <TextField id="vacancies" name='vacancies' value={postJob?.vacancies} type="number" label={t("Employee.JobPost.generalinfo.vacancies")} variant="outlined" onChange={(e) => handleChange(e, "vacancies")} />
                                {formErrors?.vacancies && <span style={{ color: "red" }}>{formErrors.vacancies}</span>}
                            </Col>

                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <TextField id="reqExperience" name='reqExperience' value={postJob?.reqExperience} type="text" label={t("Employee.JobPost.generalinfo.requireExperience")} variant="outlined" onChange={(e) => handleChange(e, "reqExperience")} />
                                {formErrors?.reqExperience && <span style={{ color: "red" }}>{formErrors.reqExperience}</span>}
                            </Col>

                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <TextField id="salary" name='salary' type="number" value={postJob?.salary} label={t("Employee.JobPost.generalinfo.salary")} variant="outlined" onChange={(e) => handleChange(e, "salary")} />
                                {formErrors?.salary && <span style={{ color: "red" }}>{formErrors.salary}</span>}
                            </Col>

                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <TextField id="benifits" name='benifits' value={postJob?.benifits} type="text" label={t("Employee.JobPost.generalinfo.benifits")} variant="outlined" onChange={(e) => handleChange(e, "benifits")} />
                                {formErrors?.benifits && <span style={{ color: "red" }}>{formErrors.benifits}</span>}
                            </Col>
                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t("Employee.JobPost.generalinfo.type")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={postJob?.type}
                                        name="type"
                                        label={t("Employee.JobPost.generalinfo.type")}
                                        onChange={(e) => handleChange(e, "type")}
                                    >
                                        <MenuItem value="PARTTIME">{t("Employee.JobPost.generalinfo.menuItem.partTime")}</MenuItem>
                                        <MenuItem value="FULLTIME">{t("Employee.JobPost.generalinfo.menuItem.fullTime")}</MenuItem>
                                    </Select>
                                </FormControl>
                                {formErrors?.type && <span style={{ color: "red" }}>{formErrors.type}</span>}
                            </Col>

                            <Col xl={3} lg={4} md={6} className="mt-3">
                                {/* <TextField id="extraType" name='extraType' value={postJob?.extraType} type="text" label={t("Employee.JobPost.generalinfo.extraType")} variant="outlined" onChange={(e) => handleChange(e, "extraType")} /> */}
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t("Employee.JobPost.generalinfo.extraType")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={postJob?.extraType}
                                        name="extraType"
                                        label={t("Employee.JobPost.generalinfo.extraType")}
                                        onChange={(e) => handleChange(e, "extraType")}
                                    >
                                        <MenuItem value="CONTRACTUAL">{t("Employee.JobPost.generalinfo.menuItem.contractual")}</MenuItem>
                                        <MenuItem value="ONROLL">{t("Employee.JobPost.generalinfo.menuItem.onRoll")}</MenuItem>
                                    </Select>
                                    {formErrors?.type && <span style={{ color: "red" }}>{formErrors.type}</span>}
                                </FormControl>
                            </Col>

                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t("Employee.JobPost.generalinfo.shifts")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={postJob?.shifts}
                                        name="shifts"
                                        label={t("Employee.JobPost.generalinfo.shifts")}
                                        onChange={(e) => handleChange(e, "shifts")}
                                    >
                                        <MenuItem value="DAY">{t("Employee.JobPost.generalinfo.menuItem.day")}</MenuItem>
                                        <MenuItem value="NIGHT">{t("Employee.JobPost.generalinfo.menuItem.night")}</MenuItem>
                                    </Select>
                                </FormControl>
                                {formErrors?.shifts && <span style={{ color: "red" }}>{formErrors.shifts}</span>}
                            </Col>
                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <TextField id="workingHours" name='workingHours' value={postJob?.workingHours} type="number" label={t("Employee.JobPost.generalinfo.workingHours")} variant="outlined" onChange={(e) => handleChange(e, "workingHours")} />
                                {formErrors?.workingHours && <span style={{ color: "red" }}>{formErrors.workingHours}</span>}
                            </Col>
                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <TextField id="documentation" name='documentation' value={postJob?.documentation} type="text" label={t("Employee.JobPost.generalinfo.documentation")} variant="outlined" onChange={(e) => handleChange(e, "documentation")} />
                                {formErrors?.documentation && <span style={{ color: "red" }}>{formErrors.documentation}</span>}
                            </Col>
                            <Col xl={3} lg={4} md={6} className="mt-3 file">
                                <TextField id="thumbnail" name='thumbnail' type="file" label="Choose a thumbnail"
                                    variant="outlined" onChange={(e) => fileUpload(e, "thumbnail")} InputLabelProps={{ shrink: true }} />
                                {formErrors?.thumbnail && <span style={{ color: "red" }}>{formErrors.thumbnail}</span>}
                            </Col>
                            <Col xl={3} lg={4} md={6} className="mt-3 file">
                                <TextField id="bannerImage" name='bannerImage' type="file" label="Choose a bannerImage"
                                    variant="outlined" onChange={(e) => fileUpload(e, "bannerImage")} focused />
                                {formErrors?.bannerImage && <span style={{ color: "red" }}>{formErrors.bannerImage}</span>}
                            </Col>
                        </Row><Row>
                            <Col xl={6} lg={4} md={6} className="mt-3">
                                <div className="position-relative">
                                    <TextField
                                        id="description"
                                        label={t("Employee.JobPost.generalinfo.description")}
                                        multiline
                                        rows={3}
                                        name="description"
                                        value={postJob?.description}
                                        onChange={(e) => handleChange(e, "description")}
                                    />
                                </div>
                                {formErrors?.description && <span style={{ color: "red" }}>{formErrors.description}</span>}
                            </Col>
                            <Col xl={6} lg={4} md={6} className="mt-3">
                                <div className="position-relative">
                                    <TextField
                                        id="application_form"
                                        label={t("Employee.JobPost.generalinfo.applicationForm")}
                                        multiline
                                        rows={3}
                                        name="application_form"
                                        value={postJob?.application_form}
                                        onChange={(e) => handleChange(e, "application_form")}
                                    />
                                </div>
                                {formErrors?.application_form && <span style={{ color: "red" }}>{formErrors.application_form}</span>}
                            </Col>
                            <Col xl={6} lg={4} md={6} className="mt-3" >
                                <div className="position-relative">
                                    <TextField
                                        id="application_process"
                                        label={t("Employee.JobPost.generalinfo.applicationProcess")}
                                        multiline
                                        rows={3}
                                        name="application_process"
                                        value={postJob?.application_process}
                                        onChange={(e) => handleChange(e, "application_process")}
                                    />
                                </div>
                                {formErrors?.application_process && <span style={{ color: "red" }}>{formErrors.application_process}</span>}
                            </Col>

                        </Row>
                    </div>
                    <div className='mt-4 General_Info'>
                        <h1 className='heading-txt  mb-0'>{t("Employee.JobPost.location.title")}</h1>
                        <Row>
                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <TextField id="pincode" name='pincode' value={postJob?.pincode} inputProps={{ maxLength: 6 }} type="text" label={t("Employee.JobPost.location.pincode")} variant="outlined" onChange={(e) => handleChange(e, "pincode")} />
                                {formErrors?.pincode && <span style={{ color: "red" }}>{formErrors.pincode}</span>}
                            </Col>

                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t("Employee.JobPost.location.state")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={postJob?.state}
                                        name="state"
                                        label={t("Employee.JobPost.location.state")}
                                        onChange={(e) => handleChange(e, "state")}
                                    >
                                        {
                                            getStateData && getStateData.data && getStateData.data.length > 0 ?
                                                getStateData.data.map((ele: any, i: number) => <MenuItem key={i} value={ele.id}>{ele.name}</MenuItem>)
                                                : ''
                                        }
                                    </Select>
                                </FormControl>
                                {formErrors?.state && <span style={{ color: "red" }}>{formErrors.state}</span>}
                            </Col>

                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t("Employee.JobPost.location.city")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={postJob?.district}
                                        name="city"
                                        label={t("Employee.JobPost.location.city")}
                                        onChange={(e) => handleChange(e, "district")}
                                    >
                                        {
                                            getDistrictData && getDistrictData.data &&
                                            getDistrictData.data.map((ele: any, i: number) => <MenuItem key={i} value={ele.id}>{ele.name}</MenuItem>)

                                        }
                                    </Select>
                                </FormControl>
                                {formErrors?.district && <span style={{ color: "red" }}>{formErrors.district}</span>}
                            </Col>

                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <TextField id="town" name='town' value={postJob?.town} type="text" label={t("Employee.JobPost.location.town")} variant="outlined" onChange={(e) => handleChange(e, "town")} />
                                {formErrors?.town && <span style={{ color: "red" }}>{formErrors.town}</span>}
                            </Col>
                        </Row>
                    </div>
                    <div className='mt-4'>
                        <h1 className='heading-txt mb-0'>{t("Employee.JobPost.recruiterDetails.title")}</h1>
                        <Row>
                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <TextField id="phone" name='phone' value={postJob?.phone} inputProps={{ maxLength: 10 }} type="text" label={t("Employee.JobPost.recruiterDetails.phone")} variant="outlined" onChange={(e) => handleChange(e, "phone")} />
                                {formErrors?.phone && <span style={{ color: "red" }}>{formErrors.phone}</span>}
                            </Col>

                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <TextField id="email" name='email' value={postJob?.email} type="text" label={t("Employee.JobPost.recruiterDetails.email")} variant="outlined" onChange={(e) => handleChange(e, "email")} />
                                {formErrors?.email && <span style={{ color: "red" }}>{formErrors.email}</span>}
                            </Col>

                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <TextField id="startDate" InputLabelProps={{
                                    shrink: true,
                                }} name='startDate' value={postJob?.startDate} type="date" label={t("Employee.JobPost.recruiterDetails.startData")} variant="outlined" onChange={(e) => handleChange(e, "startDate")} />
                                {formErrors?.startDate && <span style={{ color: "red" }}>{formErrors.startDate}</span>}
                            </Col>

                            <Col xl={3} lg={4} md={6} className="mt-3">
                                <TextField id="endDate" InputLabelProps={{
                                    shrink: true,
                                }} name='endDate' value={postJob?.endDate} type="date" label={t("Employee.JobPost.recruiterDetails.endDate")} variant="outlined" onChange={(e) => handleChange(e, "endDate")} />
                                {formErrors?.endDate && <span style={{ color: "red" }}>{formErrors.endDate}</span>}
                            </Col >
                        </Row >
                    </div >
                    <div className='Save_Changes_btn'>
                        <button onClick={() => save()}>{(jobId) ? t("Employee.JobPost.btn.saveChanges") : "Post a job"}</button>
                        {(jobId && addIn) && <button onClick={() => handleDelete()}>Delete job</button>}
                        <button onClick={() => navigate(-1)}>Back</button>
                    </div>
                </div >
            </Container >
        </div >

    )
}

export default PostJob