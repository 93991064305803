const protocol = 'https';
const host = 'appapi.swayamconnect.org';
const port = '';
const trailUrl = 'api/v1/';

const hostUrl = `${host}/`;
const endpoint = `${protocol}://${host}${(port ? ':' + port : '')}/${trailUrl}`;

export default {
    host: host,
    apiUrl: trailUrl,
    endpoint: endpoint,
    hostUrl: hostUrl
};
