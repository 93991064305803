import Pages from "../pages";
import Employer from "../employer";
import Partner from "../partners";
import AuthStorage from "../helper/AuthStorage";
import STORAGEKEY from "../config/APP/app.config";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../redux/actions/loginAction";

const Routes = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [userType, setUserType] = useState<any>()

  useEffect(() => {
    setUserType(AuthStorage.getStorageData(STORAGEKEY.userType)?.toLowerCase())
  }, [AuthStorage.getStorageData(STORAGEKEY.userType)])

  const getUserData = useSelector((state: any) => state.login.getUserData)

  let EmployeeRoute = ['/employer/inactive_employer', '/employer/dashboard', '/employer/activejobs', '/employer/inactivejobs', '/employer/jobapplication', '/employer/myprofile', '/edit-partner-profile', '/employer/addtrainingpartner', '/employer/edit-employer-profile']
  let PartnerRoute = ['/partner/inactive_partner', '/partner/dashboard', '/partner/activecourses', '/partner/inactivecourses', '/partner/myprofile', '/partner/edit-partner-profile', '/partner/postcourses']

  useEffect(() => {
    if (userType && getUserData?.data) {
      if (EmployeeRoute.includes(location.pathname)) {
        navigate(location.pathname)
      } else if (userType === "employer") {
        if (getUserData && getUserData.data && !getUserData?.data.isActive) {
          navigate('/employer/inactive_employer')
        } else if (getUserData && getUserData.data && getUserData?.data.isActive) {
          navigate('/employer/dashboard')
        }
      } else {
        if (PartnerRoute.includes(location.pathname)) {
          navigate(location.pathname)
        } else if (userType === "partner") {
          if (getUserData && getUserData.data && !getUserData?.data.isActive) {
            navigate('/partner/inactive_partner')
          } else if (getUserData && getUserData.data && getUserData?.data.isActive) {
            navigate('/partner/dashboard')
          }
        }
      }
    }
  }, [userType, getUserData]);

  return (
    <>
      {
        userType === "employer" ?
          <Employer /> :
          userType === "partner" ?
            <Partner /> :
            <Pages />
      }
    </>
  );
};

export default Routes;
