import { TextField } from "@mui/material";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ApiPostNoAuth } from "../../helper/API/ApiData";
import { toast } from "react-toastify";
interface writeToUs {
    name?: string;
    email?: string;
    phone?: string;
    message?: string;
}

const WriteToUs = ({ show, onHide }: any) => {

    const { t } = useTranslation()

    const [writeToUs, setWriteToUs] = useState<writeToUs>({
        name: '',
        email: '',
        phone: '',
        message: '',
    })
    const [error, setError] = useState<writeToUs>({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handlechange = (e: any) => {
        let re = /^[0-9\b]+$/;
        if (e.target.name === "phone") {
            if (e.target.value === "" || re.test(e.target.value)) {
                setWriteToUs({ ...writeToUs, [e.target.name]: e.target.value })
                setError({ ...error, [e.target.name]: '' })
            }
        } else {
            setWriteToUs({ ...writeToUs, [e.target.name]: e.target.value })
            setError({ ...error, [e.target.name]: '' })

        }
    }
    const validation = () => {
        let err: writeToUs = {
            name: '',
            email: '',
            phone: '',
            message: '',
        }
        const emailRegex = RegExp(/^\S+@\S+\.\S+$/);
        let flage = false;
        if (writeToUs.name === '') {
            err.name = 'Name is required';
            flage = true;
        }
        if (writeToUs.email === '') {
            err.email = 'Email is required';
            flage = true;
        }
        if (writeToUs.email && !writeToUs.email.match(emailRegex)) {
            err.email = "Please enter a valid email address"
            flage = true;
        }

        if (writeToUs.phone === '') {
            err.phone = 'Mobile number is required';
            flage = true;
        }
        if (writeToUs.message === '') {
            err.message = 'Massage is required';
            flage = true;
        }
        if (writeToUs.message && writeToUs.message.length < 50) {
            err.message = 'Massage must be at least 50 characters';
            flage = true;
        }
        setError(err);
        return flage;
    }

    const onSubmit = () => {
        if (validation()) {
            return
        }
        const body = {
            From: ["darshandhaduk.7seasol@gmail.com", "ravisakhat.7seasol@gmail.com", "swayam@gmail.com"],
            userName: writeToUs.name,
            userEmail: writeToUs.email,
            userPhone: writeToUs.phone,
            message: writeToUs.message
        }
        ApiPostNoAuth(`user/auth/writeToUs`, body)
            .then((res: any) => {
                if (res.status === 200) {
                    toast.success("Your message send")
                    onHide()
                }
            })
            .catch((error) => {
                return error
            })
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter" setLoginType
            centered
            className="loginmodal"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body>
                <div className="loginbody row">
                    <div className="closeButton">
                        <img src="./assets/img/wrong.png" alt="" onClick={() => { onHide(true) }} />
                    </div>

                    <div className="col-lg-6">
                        <img src="./assets/img/login.png" alt="" width="100%" height="100%" />
                    </div>

                    <div className="col-lg-6 login-form ">
                        <h1>{t("writeusmodal.title")}</h1>
                        <h2>{t("writeusmodal.sub_title")}</h2>

                        <div className="mt-4">
                            <TextField id="outlined-danger" type="text" label={t("writeusmodal.name")} name="name" value={writeToUs.name} variant="outlined" onChange={(e) => handlechange(e)} />
                            {error && error.name && <span style={{ color: 'red' }}>{error.name}</span>}
                        </div>

                        <div className="mt-4">
                            <TextField id="outlined-danger" type="email" label={t("writeusmodal.emailid")} name="email" value={writeToUs.email} variant="outlined" onChange={(e) => handlechange(e)} />
                            {error && error.email && <span style={{ color: 'red' }}>{error.email}</span>}
                        </div>
                        <div className="mt-4">
                            <TextField id="outlined-danger" type="text" label={t("writeusmodal.mobileno")} name="phone" value={writeToUs.phone} variant="outlined" onChange={(e) => handlechange(e)} inputProps={{ maxLength: 10 }} />
                            {error && error.phone && <span style={{ color: 'red' }}>{error.phone}</span>}
                        </div>

                        <div className="mt-4 position-relative">
                            <TextField
                                id="outlined-multiline-static"
                                label={t("writeusmodal.aboutself")}
                                multiline
                                rows={3}
                                name="message"
                                value={writeToUs.message}
                                onChange={(e) => handlechange(e)}
                            />
                            {error && error.message && <span style={{ color: 'red' }}>{error.message}</span>}
                            <p className="text-end mt-2" style={{ cursor: "pointer", width: "fit-content", }}>{t("writeusmodal.review")} <span style={{ fontWeight: "600", color: "#000" }}>{t("writeusmodal.guidline")}</span></p>
                        </div>
                        <div className="text-center login-button mt-5">
                            <button onClick={() => onSubmit()}>Submit</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default WriteToUs