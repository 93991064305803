import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";


const AddLanduage = ({ show, onHide, keyId, lanId, header, addInCourses, addIn }: any) => {
    let name = "JOBS"
    const navigate = useNavigate()
    const handalAdd = () => {
        if (addInCourses) {
            navigate(`/partner/postcourses?key=${keyId}&lanId=${lanId}&addIn=${addIn}`)
        } else {
            navigate(`/employer/postjob?key=${keyId}&lanId=${lanId}&addIn=${addIn}`)
        }
    }
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter" setLoginType
            centered
            className="upload-image-resume"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>{header}</Modal.Header>
            <Modal.Body>
                <div className="via-checkbox">
                    <div className="text-center">
                        <label htmlFor="female">{`This ${addInCourses ? "course" : "job"} in not available in this language. You want to add?`}</label>
                    </div>
                </div>
                <div className="d-flex gap-3" style={{ justifyContent: 'center' }}>
                    <div className="mt-4 text-center">
                        <Button className="submit-btn" onClick={() => handalAdd()}>Yes</Button>
                    </div>
                    <div className="mt-4 text-center">
                        <Button className="submit-btn" onClick={() => onHide()}>No</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default AddLanduage